import { Col, Container, Row } from "reactstrap";

interface BreadcrumbProps {
  title: string;
  pageHeader: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ title, pageHeader }) => {
  return (
    <section
      className="breadcrumb__area breadcrumb__pt-200 include-bg p-relative"
      style={{ backgroundImage: `url(${pageHeader})` }}
    >
      <Container>
        <Row>
          <Col xxl={12}>
            <div className="breadcrumb__content p-relative z-index-1">
              <h3 className="breadcrumb__title m-0">{title}</h3>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Breadcrumb;
