import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const HeroArea = () => {
  return (
    <div className="tp-hero-area tp-hero-space p-relative z-index-1 fix">
      <div className="tp-hero-shape">
        <div className="shape-circle-yellow d-none"></div>
        <div className="shape-circle-blue"></div>
      </div>
      <div className="tp-hero-wapper">
        <Container>
          <Row>
            <Col xl={7} lg={7}>
              <div className="tp-hero-content">
                <div className="tp-hero-text">
                  <h2 className="tp-hero-title wow tpfadeUp" data-wow-duration=".3s" data-wow-delay=".6s">
                    Müzik Sektöründe Bağlantılı Haklar Federasyonu
                  </h2>
                  <p className="wow tpfadeUp" data-wow-duration=".5s" data-wow-delay=".8s">
                    MÜZFED, 5846 sayılı Fikir ve Sanat Eserleri kanununa göre kurulmuş olan Müyap, Müyorbir, Müyabir,
                    Müzikbir ve TSMB meslek birliklerinin kurduğu müzik sektöründe fonogram yapımcıları ve icracıların
                    haklarını koruyan federasyondur.
                  </p>

                  <div className="pb-30 wow tpfadeIn" data-wow-duration=".7s" data-wow-delay="1.2s">
                    <div className="flex-column flex-sm-row d-flex align-items-center justify-content-between">
                      <div className="tp-brand-icon text-center">
                        <Link to="https://tr.mu-yap.org" target="_blank">
                          <img
                            className="rounded img-responsive"
                            src={require("@muzfed/assets/img/logo/meslek-birlikleri/muyap.jpg")}
                            alt=""
                            width={100}
                          />
                        </Link>
                      </div>
                      <div className="tp-brand-icon text-center">
                        <Link to="https://muyorbir.org.tr/" target="_blank">
                          <img
                            className="img-responsive"
                            src={require("@muzfed/assets/img/logo/meslek-birlikleri/muyorbir.jpg")}
                            alt=""
                            width={100}
                          />
                        </Link>
                      </div>
                      <div className="tp-brand-icon text-center">
                        <Link to="https://muyabir.org.tr/" target="_blank">
                          <img
                            className="img-responsive"
                            src={require("@muzfed/assets/img/logo/meslek-birlikleri/muyabir.jpg")}
                            alt=""
                            width={100}
                          />
                        </Link>
                      </div>
                      <div className="tp-brand-icon text-center">
                        <Link to="http://muzikbir.org/" target="_blank">
                          <img
                            className="img-responsive"
                            src={require("@muzfed/assets/img/logo/meslek-birlikleri/muzbir.jpg")}
                            alt=""
                            width={100}
                          />
                        </Link>
                      </div>
                      <div className="tp-brand-icon text-center">
                        <Link to="http://tsmb.org/" target="_blank">
                          <img
                            className="img-responsive"
                            src={require("@muzfed/assets/img/logo/meslek-birlikleri/icraci-sanaticlar-ve-muzisyenler.jpg")}
                            alt=""
                            width={100}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>

                  <Col lg={12}>
                    <div className="tp-sv-bo wow tpfadeUp" data-wow-duration="0.5" data-wow-delay="0.8">
                      <div className="tp-service-item border d-flex py-4 px-3">
                        <div className="tp-sv-img">
                          <img src={require("@muzfed/assets/img/logo/otellisanslama.png")} alt="Otel Lisanslama Logo" />
                        </div>
                        <div className="tp-sv-content">
                          <h3 className=" tp-sv-title fs-4 mb-3">
                            <Link to="tp-service-item d-flex">Müzik Lisans Belgesi</Link>
                          </h3>
                          <p className="mb-3 p-0">
                            Müzik lisans belgesi almak için başvurunuzu otellisanslama.org web sitesinden
                            yapabilirsiniz.
                          </p>
                          <div className="tp-sv-link fs-5">
                            <Link to="https://otellisanslama.org" target="_blank">
                              <i className="far fa-arrow-right"></i> Başvuru yapmak için tıklayınız
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
              </div>
            </Col>
            <Col xl={5} lg={5} className="d-none d-lg-block">
              <div className="tp-hero-big-img wow fadeInRight" data-wow-duration=".7s" data-wow-delay="1.2s">
                <img src={require("@muzfed/assets/img/hero/Hero.png")} alt="" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default HeroArea;
