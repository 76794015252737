import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

const LicenceOfAuthorization = () => {
  return (
    <div className="ac-about-content-area pt-30 pb-30">
      <Container>
        <Row>
          <Col className="wow tpfadeLeft" data-wow-duration=".5s" data-wow-delay=".7s">
            <div className="ac-about-left">
              <Link
                className="text-primary"
                target="_blank"
                download={true}
                to={process.env.PUBLIC_URL + "/assets/docs/Yetki-Belgesi.doc"}
              >
                Yetki Belgesini İndirmek İçin Tıklayınız
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LicenceOfAuthorization;
