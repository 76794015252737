import { Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";

const MembershipTerms = () => (
  <Container className="pt-30 pb-30">
    <Row>
      <Col className="wow tpfadeLeft" data-wow-duration=".5s" data-wow-delay=".7s">
        <h5 className="text-black">
          MÜZFED - MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’na üye olabilmek için aşağıdaki nitelikleri taşımak
          gerekir:
        </h5>

        <ListGroup flush numbered className="mt-25">
          <ListGroupItem>
            5846 sayılı Fikir ve Sanat Eserleri Kanunu kapsamında Türkiye’de fonogram yapımcılığı ve müzik icracıları
            alanlarında faaliyet gösteren meslek birliği olmak,
          </ListGroupItem>
          <ListGroupItem>Meslek birliği olarak medeni hakları kullanma ehliyetine sahip olmak,</ListGroupItem>
          <ListGroupItem>
            MÜZFED - MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’nca belirlenen koşullara uygun olarak belirlenmiş
            yetki belgesini vermek ve giriş ödentisini ödemek.
          </ListGroupItem>
        </ListGroup>
      </Col>
    </Row>
  </Container>
);

export default MembershipTerms;
