import "@muzfed/assets/scss/index.scss";
import CookieConsent from "react-cookie-consent";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Container } from "reactstrap";
import Layout from "./layouts/Layout";
import SubPageLayout from "./layouts/SubPageLayout";
import About from "./pages/About";
import BoardOfDirectors from "./pages/BoardOfDirectors";
import Charter from "./pages/Charter";
import Companies from "./pages/Companies";
import Contact from "./pages/Contact";
import Directives from "./pages/Directives";
import Faq from "./pages/Faq";
import GeneralAssemblyDecisions from "./pages/GeneralAssemblyDecisions";
import Home from "./pages/Home";
import LicenceOfAuthorization from "./pages/LicenceOfAuthorization";
import LicenseContract from "./pages/LicenseContract";
import Members from "./pages/Members";
import MembershipTerms from "./pages/MembershipTerms";
import Privacy from "./pages/Privacy";
import Tariffs from "./pages/Tariffs";
import Telif from "./pages/Telif";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          ></Route>
          <Route
            path="/iletisim"
            element={
              <SubPageLayout>
                <Contact />
              </SubPageLayout>
            }
          />
          <Route
            path="/hakkimizda/biz-kimiz"
            element={
              <SubPageLayout>
                <About />
              </SubPageLayout>
            }
          />
          <Route
            path="/hakkimizda/tuzuk"
            element={
              <SubPageLayout>
                <Charter />
              </SubPageLayout>
            }
          />
          <Route
            path="/hakkimizda/yonetim"
            element={
              <SubPageLayout>
                <BoardOfDirectors />
              </SubPageLayout>
            }
          />
          <Route
            path="/hakkimizda/uyelik-sartlari"
            element={
              <SubPageLayout>
                <MembershipTerms />
              </SubPageLayout>
            }
          />
          <Route
            path="/hakkimizda/uyeler"
            element={
              <SubPageLayout>
                <Members />
              </SubPageLayout>
            }
          />
          <Route
            path="/hakkimizda/yetki-belgesi"
            element={
              <SubPageLayout>
                <LicenceOfAuthorization />
              </SubPageLayout>
            }
          />
          <Route
            path="/hakkimizda/lisans-sozlesmesi"
            element={
              <SubPageLayout>
                <LicenseContract />
              </SubPageLayout>
            }
          />
          <Route
            path="/firmalar"
            element={
              <SubPageLayout>
                <Companies />
              </SubPageLayout>
            }
          />
          <Route
            path="/sikca-sorulan-sorular"
            element={
              <SubPageLayout>
                <Faq />
              </SubPageLayout>
            }
          />
          <Route
            path="/tarifeler"
            element={
              <SubPageLayout>
                <Tariffs />
              </SubPageLayout>
            }
          />
          <Route
            path="/gizlilik"
            element={
              <SubPageLayout>
                <Privacy />
              </SubPageLayout>
            }
          />
          <Route
            path="/hakkimizda/telif-haklari-alaninda-meslek-birligi-yonetmeliginin-58-maddesi"
            element={
              <SubPageLayout>
                <Telif />
              </SubPageLayout>
            }
          />
          <Route
            path="/hakkimizda/genel-kurur-kararlari"
            element={
              <SubPageLayout>
                <GeneralAssemblyDecisions />
              </SubPageLayout>
            }
          />
          <Route
            path="/hakkimizda/yonergeler"
            element={
              <SubPageLayout>
                <Directives />
              </SubPageLayout>
            }
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer />

      <CookieConsent
        location="bottom"
        buttonText="Onaylıyorum"
        cookieName="muzfed-cookie"
        style={{ background: "#c03c43" }}
        buttonStyle={{
          color: "#111",
          background: "#eee",
          borderRadius: "3px",
        }}
        buttonClasses="btn"
      >
        <Container>
          Web sitemizde size en iyi deneyimi sunabilmemiz için çerezleri kullanıyoruz. Bu siteyi kullanmaya devam
          ederseniz, bunu kabul ettiğinizi varsayarız.
          <hr className="border-white" />
          <a href="/gizlilik">Ayrıntılı Bilgi</a>
        </Container>
      </CookieConsent>
    </>
  );
}

export default App;
