import ApplicationFormText from "@muzfed/components/PrivacyText/ApplicationFormText";
import ApplicationGeneralExplanationText from "@muzfed/components/PrivacyText/ApplicationGeneralExplanationText";
import ClarificationTermText from "@muzfed/components/PrivacyText/ClarificationTermText";
import CookiePolicyText from "@muzfed/components/PrivacyText/CookiePolicyText";
import DisposalPolicyText from "@muzfed/components/PrivacyText/DisposalPolicyText";
import ProtectionOfPersonalDataText from "@muzfed/components/PrivacyText/ProtectionOfPersonalDataText";
import TermsOfUseText from "@muzfed/components/PrivacyText/TermsOfUseText";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const Privacy = () => {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has("t")) {
      setActiveTab(Number(searchParams.get("t")));
    }
  }, [searchParams]);

  const tabs = useMemo(
    () => [
      { title: "Kişisel Verilerin Korunması ve İşlenmesi Politikası", id: 1, comp: <ProtectionOfPersonalDataText /> },
      { title: "Aydınlatma Metni ve Gizlilik Politikası", id: 2, comp: <ClarificationTermText /> },
      { title: "Kullanım Koşulları", id: 3, comp: <TermsOfUseText /> },
      { title: "Çerez Politikası", id: 4, comp: <CookiePolicyText /> },
      { title: "Kişisel Verileri Saklama ve İmha Politikası", id: 5, comp: <DisposalPolicyText /> },
      { title: "Veri Sorumlusuna Genel Başvuru Açıklamaları", id: 6, comp: <ApplicationGeneralExplanationText /> },
      { title: "Başvuru Formu", id: 7, comp: <ApplicationFormText /> },
    ],
    []
  );

  return (
    <div className="job-area pt-30 pb-30">
      <Container>
        <Row className="justify-content-center mb-80">
          <Col md={10} className="p-0 text-center">
            <div className="tp-project-tab-button masonary-menu">
              {tabs.map((item) => (
                <button
                  key={item.id}
                  className={`${activeTab === item.id ? "active" : ""}`}
                  onClick={() => setActiveTab(item.id)}
                >
                  <span>{item.title}</span>
                </button>
              ))}
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <div className="col-xl-10">{tabs.find((i) => i.id === activeTab)?.comp || null}</div>
        </Row>
      </Container>
    </div>
  );
};

export default Privacy;
