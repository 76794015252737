import React from "react";
import ReactPaginate from "react-paginate";
import { useLocation, useNavigate } from "react-router-dom";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const handlePageClick = ({ selected }: { selected: number }) => {
    searchParams.set("page", (selected + 1).toString());
    const newSearch = searchParams.toString();
    const url = `${location.pathname}?${newSearch}`;
    navigate(url, { replace: true });
  };

  return totalPages > 0 ? (
    <ReactPaginate
      initialPage={currentPage}
      breakLabel="..."
      nextLabel=">"
      onPageChange={handlePageClick}
      pageRangeDisplayed={5}
      pageCount={totalPages}
      previousLabel="<"
      renderOnZeroPageCount={null}
      containerClassName="pagination job-pagination mb-0 d-flex justify-content-center"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link"
      nextClassName="page-item"
      nextLinkClassName="page-link"
      activeClassName="active"
    />
  ) : null;
};

export default Pagination;
