import useSticky from "@muzfed/hooks/useSticky";
import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidabar";

interface MobileMenuProps {
  logo?: string;
  bg?: string;
  transparent?: boolean;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ bg, transparent = true }) => {
  const { headerSticky } = useSticky();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  return (
    <React.Fragment>
      <div
        id="header-sticky-mobile"
        className={`tp-md-menu ${transparent ? "header-transparent" : ""} d-lg-none pt-40 pb-40 
    ${bg ? bg : ""} ${headerSticky ? "header-sticky" : ""}`}
      >
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-6">
              <div className="tp-logo">
                <Link to="#!">
                  <img src={require("@muzfed/assets/img/logo/muzfed-logo.png")} alt="" />
                </Link>
              </div>
            </div>
            <div className="col-6">
              <div className="bar text-end">
                <button className="tp-menu-bar" onClick={() => setSidebarOpen(true)} type="button">
                  <i className="fal fa-bars"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* off canvas start */}
      <Sidebar isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
      {/* off canvas end */}
    </React.Fragment>
  );
};

export default MobileMenu;
