import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const Directives = () => (
  <div className="ac-about-content-area pb-30 pt-30">
    <Container>
      <Row>
        <Col className="wow tpfadeLeft" data-wow-duration=".5s" data-wow-delay=".7s">
          <div className="ac-about-left">
            <ul>
              <li>
                <Link download={true} to="/assets/docs/yonergeler/muzfed-haysiyet-kurulu-yonergesi.pdf" target="_blank">
                  MÜZFED Haysiyet Kurulu Yönergesi’ni indirmek için tıklayınız
                </Link>
              </li>
              <li>
                <Link
                  download={true}
                  to="/assets/docs/yonergeler/muzfed-teknik-bilim-kurulu-yonergesi.pdf"
                  target="_blank"
                >
                  MÜZFED Teknik Bilim Kurulu Yönergesi’ni indirmek için tıklayınız
                </Link>
              </li>
              <li>
                <Link
                  download={true}
                  to="/assets/docs/yonergeler/muzfed-denetleme-kurulu-yonergesi.pdf"
                  target="_blank"
                >
                  MÜZFED Denetleme Kurulu Yönergesi’ni indirmek için tıklayınız
                </Link>
              </li>
              <li>
                <Link
                  download={true}
                  to="/assets/docs/yonergeler/muzfed-dagitim-yonergesi.pdf"
                  target="_blank"
                >
                  MÜZFED Dağıtım Yönergesi’ni indirmek için tıklayınız
                </Link>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Directives;
