import ClipPath from "@muzfed/svg/ClipPath";
import UpArrow from "@muzfed/svg/UpArrow";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Tooltip } from "reactstrap";

const Footer = () => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <footer className="p-relative">
      <button className="scrollTop d-none d-md-block" data-target="html">
        <Link to={"/"}>
          <div className="tp-backto-top">
            <UpArrow />
          </div>
        </Link>
      </button>
      <div className="footer-clip-shape">
        <ClipPath />
      </div>
      <div className="tp-clip-height-one"></div>
      <div className="tp-clip-height-two"></div>
      <div className="tp-footer-area tp-footer-space black-bg p-relative fix pt-0">
        <div className="tp-footer-border-shape d-none"></div>
        <div className="tp-footer-border-shape-two"></div>
        <div className="circle-animation footer-animation d-none d-md-block">
          <span className="tp-circle-3"></span>
        </div>
        <div className="container">
          <div className="tp-footer-widget wow tpfadeUp" data-wow-duration=".5s" data-wow-delay=".5s">
            <div className="row align-items-center">
              <div className="col-xl-7 col-lg-7 col-md-7 col-12">
                <div className="tp-footer-top">
                  <h5 className="tp-footer-title">
                    MÜZİK SEKTÖRÜNDE <br />
                    BAĞLANTILI HAKLAR FEDERASYONU
                  </h5>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5 col-12">
                <div className="tp-footer-button text-start text-md-end">
                  <Link to="https://otellisanslama.org" target="_blank" className="tp-btn">
                    Başvuru Yap
                    <i className="far fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tp-copyright-area pb-90 wow tpfadeUp" data-wow-duration=".5s" data-wow-delay=".7s">
          <Container>
            <div className="tp-copyright-box align-items-center">
              <Row className="align-items-center">
                <Col md={3}>
                  <div className="tp-copyright-text z-index-1">
                    <p className="m-0">Her Hakkı Saklıdır</p>
                  </div>
                </Col>
                <Col md={9}>
                  <div className="tp-copyright-right text-md-end text-start">
                    <Link to="/gizlilik?t=1">Kişisel Verilen Korunması ve İşlenmesi Kanunu</Link>
                    <Link to="/gizlilik?t=4">Çerez Politikası</Link>
                    <Link to="/gizlilik?t=2">Aydınlatma ve Gizlilik Politikası</Link>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="tp-copyright-logo-box">
              <Row className="align-items-center">
                <Col md={4}>
                  <div className="tp-copyright-logo mt-35">
                    <Link to="/">
                      <img src={require("@muzfed/assets/img/logo/muzfed-logo-light.png")} alt="" />
                    </Link>
                  </div>
                </Col>
                <Col md={8} className="d-flex justify-content-end align-items-center">
                  <Link to="http://kuryaz.com" target="_blank" rel="noreferrer" id="kuryazLink">
                    <img
                      src={require("@muzfed/assets/img/logo/kurgu_logo.png")}
                      alt="Kurgu Yazılım"
                      width={50}
                      height={50}
                    />
                  </Link>
                  <Tooltip isOpen={tooltipOpen} target="kuryazLink" toggle={toggle}>
                    Kurgu Yazılım
                  </Tooltip>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
