import menuData from "@muzfed/data/menu-data";
import { useLocation } from "react-router-dom";

const usePageTitle = () => {
  const location = useLocation();
  let title = "Müzfed";

  if (typeof menuData.find((x) => x.link === location.pathname) !== "undefined") {
    const menu = menuData.find((x) => x.link === location.pathname);
    title = menu && menu.title ? menu.title : "Müzfed";
  } else {
    for (const item of menuData) {
      if (item.has_dropdown === true && item.sub_menus) {
        for (const submenuItem of item.sub_menus) {
          if (submenuItem.link === location.pathname) {
            title = submenuItem.title;
          }
        }
      }
    }
  }

  return title;
};

export default usePageTitle;
