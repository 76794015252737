import { Col, Container, Row } from "reactstrap";

const Telif = () => (
  <div className="ac-about-content-area pb-30 pt-30">
    <Container>
      <Row>
        <Col className="wow tpfadeLeft" data-wow-duration=".5s" data-wow-delay=".7s">
          {/* <div className="ac-about-left">
            <p>
              <strong>
                TELİF HAKLARI ALANINDA MESLEK BİRLİĞİ YÖNETMELİĞİNİN 58. MADDESİ GEREĞİNCE YAYINLANMASI GEREKEN BİLGİLER
              </strong>
            </p>

            <strong>MÜYAP TARAFINDAN UYGULANAN AİDATLAR:</strong>

            <p>2022 yılında yapılan Olağanüstü Genel Kurul toplantısında;</p>
            <ul>
              <li>Müyap üyeliği giriş ödentisi 50.000,00 TL,</li>
              <li>Yıllık aidatların ise A ve B grubu üyeler için 4.000,00 TL, C grubu üyeler için de 10.000,00 TL</li>
            </ul>
            <p>Olarak belirlenmesine karar verilmiştir.</p>

            <strong>KESİNTİ ORANLARI</strong>
            <p>
              MÜYAP Genel Kurulu tarafından kabul edilen MÜ-YAP Meslek Birliği Payına İlişkin Yönerge’nin ilgili 6.01.01
              maddesine göre MÜ-YAP’ın hak takibini yaptığı her türlü kullanım bakımından tahsil edilen tahsil edilen
              ücretlerden yıllık ortalaması %30’u (Yüzde/Otuz) geçmeyecek şekilde MÜ-YAP payı olarak kesilir ve üyelere
              dağıtılmaz.
            </p>

            <strong>MÜYAP’IN TEMSİL SÖZLEŞMESİ YAPTIĞI ULUSLARARASI KURULUŞLAR</strong>

            <p>
              Müyap meslek birliğinin uluslararası alanda karşılıklı temsilcilik anlaşması yaptığı bir uluslararası
              kuruluş bulunmamaktadır.
            </p>

            <strong>ŞİKAYET BAŞVURU SÜREÇLERİ</strong>
            <p>
              Müyap meslek birliği gerek kendi bünyesinde bulunan ve gerekse de hizmet sözleşmesi imzalayarak hizmet
              aldığı avukatlar vasıtası ile hukuki iş ve işlemlerini gerçekleştirmektedir. Müyap’tan herhangi bir
              şekilde yazılı izni almaksızın Müyap’ın hak takip yetkisine sahip olduğu fonogramları kullanarak müzik
              kullanımı / iletimi yapan kullanıcılar hakkında 5846 sayılı Fikir ve Sanat Eserleri Kanunu’nun ilgili 71.
              Ve diğer maddelerine göre Cumhuriyet Başsavcılıklarına başvuru yapılması suretiyle şikayet başvuruları
              yapılmaktadır. Kullanıcıların Müyap meslek birliği ile izin sözleşmelerini imzalamaları ile birlikte de,
              yapılmış olan bu şikayet başvurularından vazgeçilmektedir.
            </p>

            <strong>DAĞITILAMAYAN GELİRLER</strong>
            <p>
              Müyap meslek birliğinin kendi iç düzenlemeleri ve uygulamalarına göre dağıtılmayan gelirleri
              bulunmamaktadır.
            </p>
          </div> */}
        </Col>
      </Row>
    </Container>
  </div>
);

export default Telif;
