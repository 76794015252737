import { Link } from "react-router-dom";
import { Table } from "reactstrap";

const DisposalPolicyText = () => (
  <>
    <h3 className="h3 h3-responsive text-center mb-5">KİŞİSEL VERİLERİ SAKLAMA VE İMHA POLİTİKASI</h3>
    <h3 className="h3 h3-responsive">GİRİŞ</h3>
    <p>
      6698 sayılı Kişisel Verilerin Korunması Kanunu ve Kişisel Verilerin Silinmesi, Yok Edilmesi veya Anonim Hale
      Getirilmesi Kapsamında kişisel verisini işlediğimiz veri sahiplerinin verilerinin ne kadar süre sistemimizde
      tutulduğunu ve imha koşulları, süreleri hakkında bilgilendirmek isteriz.
    </p>
    <p>
      Veri sorumlusu olarak{" "}
      <Link rel="noopener noreferrer" to="https://muzfed.org" target="_blank" style={{ textDecoration: "underline" }}>
        https://muzfed.org
      </Link>{" "}
      alan adlı internet sitesi adına MÜZFED, söz konusu imha politikasını uygulayacaktır.
    </p>

    <h3 className="h3 h3-responsive">A. TANIMLAR</h3>
    <p>
      <b>Alıcı Grubu</b>: Veri sorumlusu tarafından kişisel verilerin aktarıldığı gerçek veya tüzel kişi kategorisini,
    </p>
    <p>
      <b>Veri İşleyen</b>: Verilerin teknik olarak depolanması, korunması ve yedeklenmesinden sorumlu olan kişi ya da
      birim hariç olmak üzere veri sorumlusu organizasyonu içerisinde veya veri sorumlusundan aldığı yetki ve talimat
      doğrultusunda kişisel verileri işleyen kişilerdir.
    </p>
    <p>
      <b>Veri Kayıt Sistemi</b>: Kişisel verilerin belirli kriterlere göre yapılandırılarak işlendiği kayıt sistemi.
    </p>
    <p>
      <b>VERBİS</b>: Veri Sorumluları Sicil Bilgi Sistemi.
    </p>
    <p>
      <b>İmha</b>: Kişisel verilerin silinmesi, yok edilmesi veya anonim hale getirilmesi.
    </p>
    <p>
      <b>Kayıt Ortamı</b>: Tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak
      kaydıyla otomatik olmayan yollarla işlenen kişisel verilerin bulunduğu her türlü ortam.
    </p>
    <p>
      <b>Yönetmelik</b>: 28 Ekim 2017 tarihli Resmi Gazetede yayımlanan Kişisel Verilerin Silinmesi, Yok Edilmesi veya
      Anonim Hale Getirilmesi Hakkında Yönetmelik.
    </p>
    <p>
      <b>Politika</b>: Kişisel Verileri Saklama ve İmha Politikası
    </p>
    <p>
      <b>Kişisel Veri</b>: Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi.
    </p>
    <p>
      <b>Kişisel Verilerin İşlenmesi</b>: Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri
      kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması,
      muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir
      hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her
      türlü işlem.
    </p>
    <p>
      <b>Kişisel Verilerin Anonim Hale Getirilmesi</b>: Kişisel verilerin, başka verilerle eşleştirilerek dahi hiçbir
      surette kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hâle getirilmesi.
    </p>
    <p>
      <b>Kişisel Verilerin Silinmesi</b>: Kişisel verilerin ilgili kullanıcılar için hiçbir şekilde erişilemez ve tekrar
      kullanılamaz hale getirilmesi.
    </p>
    <p>
      <b>Kişisel Verilerin Yok Edilmesi</b>: Kişisel verilerin hiç kimse tarafından hiçbir şekilde erişilemez, geri
      getirilemez ve tekrar kullanılamaz hale getirilmesi işlemi.
    </p>
    <p>
      <b>Kurul</b>: Kişisel Verileri Koruma Kurulu.
    </p>
    <p>
      <b>Periyodik İmha</b>: Kanun’da yer alan kişisel verilerin işlenme şartlarının tamamının ortadan kalkması
      durumunda kişisel verileri saklama ve imha politikasında belirtilen ve tekrar eden aralıklarla resen
      gerçekleştirilecek silme, yok etme veya anonim hale getirme işlemi.
    </p>
    <p>
      <b>Veri Sahibi/İlgili Kişi</b>: Kişisel verisi işlenen gerçek kişi.
    </p>
    <p>
      <b>Kişisel Veri Envanteri</b>: Veri sorumlularının iş süreçlerine bağlı olarak gerçekleştirmekte oldukları kişisel
      verileri işleme faaliyetlerini, kişisel verileri işleme amaçları ve hukuki sebebi, veri kategorisi, aktarılan
      alıcı grubu ve veri konusu kişi grubuyla ilişkilendirerek oluşturdukları ve kişisel verilerin işlendikleri amaçlar
      için gerekli olan azami muhafaza edilme süresini, yabancı ülkelere aktarımı öngörülen kişisel verileri ve veri
      güvenliğine ilişkin alınan tedbirleri açıklayarak detaylandırdıkları envanter.
    </p>

    <h3 className="h3 h3-responsive mt-5">B. İLKELER</h3>
    <p>
      Öncelikle VERİ SORUMLUSU olarak gereklere uygun bir veri saklama yöntemi ve aracı kullandığımızı belirtmek
      isteriz.
    </p>

    <ol style={{ listStyle: "disc" }}>
      <li>
        6698 sayılı yasaya ve yönetmeliklere ve 108+ sözleşmesine ve Kişisel Verilerin Korunması Kurulu Kararlarına
        aykırı bir imha politikası benimsenmemiştir.
      </li>
      <li>
        Yetkisiz erişim, değiştirme veya yayımlama yanında kazara veya yetkisiz tahribe karşı otomatik veri dosyalarında
        yer alan kişisel verilerin korunması için uygun güvenlik önlemleri alınmıştır.
      </li>
      <li>
        Hem kazara kayıp veya tahrip gibi doğal tehlikelere karşı, hem de yetkisiz erişim, verilerin dolandırıcılık
        amacıyla kötüye kullanımı gibi insan kaynaklı tehlikelere ya da bilgisayar virüslerinin bulaşmasına karşı
        dosyaları koruyacak uygun önlemler alınmıştır.
      </li>
      <li>
        Kişisel verilerin korunması politikamız ve aydınlatma metninde belirttiğimiz alanlarda topladığımız kişisel
        verileriniz güvenli alanda kayıt altına alınmakta, saklanmakta ve hukuki yükümlülükler gereği saklama
        faaliyetimiz sonlandıktan sonra periyodik imha süreçleri tamamlanana kadar 3 yıl saklanmaktadır.
      </li>
      <li>
        6698 sayılı Kanun ve Yönetmelik tarafımıza kişisel verilerin imha yöntemi sürecini seçme ve yönetme hakkı
        tanımıştır. Kişisel verinin türüne göre veri sorumlusu imha yöntemini kendisi belirleyecektir. İlgili kişinin
        talebi olması durumunda ise uygun yöntem gerekçe açıklayarak seçilecektir. Veri sorumlusu veriyi imha etmeden
        önce ilgili kişinin kayıtlı e-posta adresine ya da kayıtlı adresine bildirim yapacak ve verinin hangi yöntemle
        imha edileceği bilgisini verecektir.
      </li>
      <li>
        Kişisel veriler imha edilirken de bu süreçte gerekli idari ve teknik tedbirler alınacaktır. İmha edildikten
        sonra kayıt altına alınacak ve güvenli bir ortamda en az 3 yıl saklanacaktır. Hukuki yükümlülükler dolayısı ile
        tutulacak süre hükümleri saklıdır.
      </li>
      <li>
        MÜZFED federasyonu ile üye meslek birliklerinde aktif olmayan müşteri, çalışan adayı, çalışan, taşeron ve
        tedarikçi verileri kanunda tutulma süreleri hariç olmak üzere derhal imha edilecek ve imha edildiğine dair bilgi
        ile imha yöntemi ilgili kişiye uygun yöntemle bildirilecektir.
      </li>
      <li>
        Kanun’un 5. ve 6. maddelerinde yer alan kişisel verilerin işlenme şartlarının tamamının ortadan kalkması
        halinde, kişisel veriler VERİ SORUMLULARI tarafından resen veya ilgili kişinin talebi üzerine silinmekte, yok
        edilmekte veya anonim hale getirilmektedir.
      </li>
      <li>
        İlgili kişi de VERİ SORUMLULARINDAN verilerinin silinmesini talep edebilecektir. Bu durumda MÜZFED federasyonu
        en geç 30 gün içerisinde başvuruya yanıt verir ve verilerin aktarıldığı gruplar da başvuru hakkında
        bilgilendirilecek ve silme şartları oluştu ise veri silinecektir. Silinme şartları oluşmayan kişisel verilerin
        neden silinmediği ile ilgili gerekçeli olarak ilgili kişiye yanıt verilecek ve ne zaman silineceği bilgisi
        ayrıntılı olarak açıklanacaktır.
      </li>
    </ol>

    <h3 className="h3 h3-responsive mt-5">C. SAKLAMAYI VE İMHAYI GEREKTİREN SEBEPLERE İLİŞKİN AÇIKLAMALAR</h3>
    <p>İlgili kişinin kişisel verileri</p>
    <ol type="i">
      <li>
        Fonogram yapımcılarını ve icracı sanatçılarıkanun ve tüzük amaçları çerçevesinde bir araya gelmesini sağlamak,
      </li>
      <li>
        Fikir ve Sanat Eserleri Kanunu gereği fonogram yapımcılarına ve icracı sanatçılara tanınmış hakların idaresini
        ve takibini sağlamak, ortak çıkarları korumak, fonogram yapımcılarına ve icracı sanatçılara ödenmesi gereken
        paraların tahsilini sağlamak ve paylaştırmak,
      </li>
      <li>
        Birliğe üye fonogram yapımcılarına ve icracı sanaçılara haklarının gelişmesi için Kamu ve Özel sektörle iş
        birliği yapmak ve telif haklarının gelişmesi için çalışmak,
      </li>
      <li>
        Birliğe üye fonogram yapımcılarına ve icracı sanaatçılara haklarının takibi için gerektiğinde yasama, yürütme,
        yargı organlarına başvurmak,
      </li>
      <li>
        <Link rel="noopener noreferrer" to="https://muzfed.org" target="_blank" style={{ textDecoration: "underline" }}>
          https://muzfed.org
        </Link>{" "}
        uzantılı resmi internet sitesinde MÜZFED ve üyelerinin tanıtımını yapmak, MÜZFED ve üyelerinin mali haklarını
        kullanmak için MÜZFED ile sözleşme imzalamak isteyen kullanıcıları bilgilendirmek, MÜZFED ile lisans sözleşmesi
        imzalamak isteyen kullanıcıların MÜZFED üyeleri ve bu üyelere ait fonogramlardan, eserlerden ve icralardan
        oluşan repertuar hakkında bilgi edinilebilmesini sağlamak,
      </li>
      <li>
        <Link
          rel="noopener noreferrer"
          to="https://www.muzikanaliz.com"
          target="_blank"
          style={{ textDecoration: "underline" }}
        >
          https://www.muzikanaliz.com
        </Link>{" "}
        uzantılı internet sitesinde ‘Müzik Analiz’ adıyla yayınlanacak dijital platform dergisinde, müzik haberleri
        yaparak, birliğe üye olan fonogram yapımcıları, ile müzik sektöründe yer alan icracı sanatçılar, eser sahipleri,
        yayın kuruluşları ve bunlara ek olarak yargı mercileri üniversiteleri, fikri haklar alanında faaliyet gösteren
        merkezleri ve faaliyet alanı müzik ile ilgili olan tüm iştiraklerin bilgilenmesini sağlamak ve tanıtımlarını
        yapmak,
      </li>
      <li>
        MÜZFED faaliyetleri kapsamında
        <Link rel="noopener noreferrer" to="https://muzfed.org" target="_blank" style={{ textDecoration: "underline" }}>
          https://muzfed.org
        </Link>{" "}
        uzantılı internet sitesinde MÜZFED ile bir arada lisans sözleşmesi imzalamak isteyen umuma açık mahal ve diğer
        kullanıcıların kendilerine ait adres bilgilerini, bulundukları il, ilçe bilgilerini, lisans almak istedikleri
        işletmelerinin metrekaresi, müzik iletimini ne şekilde yaptıkları, kimlik veya vergi numaraları, işletmelerinin
        konumları, telefon ve elektronik posta adresleri, kredi kartı bilgileri ve benzeri kişisel verilerini girmek
        suretiyle elektronik ortamda lisans sözleşmesi imzalayabilmelerini sağlamak,
      </li>
      <li>MÜZFED’in iş süreçlerinin yürütülmesi, organizasyon faaliyetinin devamlılığını sağlamak,</li>
      <li>Talep ve şikayetlerin alınması ve giderilmesini sağlamak,</li>
      <li>MÜZFED’in lisanslama faaliyetlerini gerçekleştirmek,</li>
      <li>Mali hak kullanım ve telif hakkı başvurularını gerçekleştirmek ve ilgili konularda bilgilendirmek,</li>
      <li>Dava ve icra takiplerini yürütmek,</li>
      <li>Sözleşme ilişkilerini takip etmek,</li>
      <li>Kamu kurum ve kuruluşlarına bildirmek,</li>
      <li>Finansal işlemleri gerçekleştirmek,</li>
      <li>Tanıtım ve lisanslama faaliyetlerini gerçekleştirmek,</li>
      <li>MÜZFED’in meşru amaçlarını sürdürmek.</li>
    </ol>
    <p>
      amacıyla kişisel verileriniz işlenmekte ve saklanmaktadır. Saklama faaliyeti kanundan doğan saklama zorunlulukları
      için öngörülen süreden fazla değildir.
    </p>

    <h5 className="h5 h5-responsive mt-4">Bu amaçla,</h5>

    <ol style={{ listStyle: "disc" }}>
      <li>
        Kişisel verilerin sözleşmelerin kurulması ve ifası ile doğrudan doğruya ilgili olması nedeniyle saklanması,
      </li>
      <li>Kişisel verilerin bir hakkın tesisi, kullanılması veya korunması amacıyla saklanması,</li>
      <li>
        Kişisel verilerin kişilerin temel hak ve özgürlüklerine zarar vermemek kaydıyla MÜZFED’in meşru menfaatleri için
        saklanmasının zorunlu olması,
      </li>
      <li>Kişisel verilerin MÜZFED’in herhangi bir hukuki yükümlülüğünü yerine getirmesi amacıyla saklanması,</li>
      <li>Mevzuatta kişisel verilerin saklanmasının açıkça öngörülmesi,</li>
      <li>
        Veri sahiplerinin açık rızasının alınmasını gerektiren saklama faaliyetleri açısından veri sahiplerinin açık
        rızasının bulunması,
      </li>
    </ol>
    <p>hususlarına dikkat edilmektedir.</p>

    <h5 className="h5 h5-responsive mt-4">Verileriniz,</h5>
    <ol style={{ listStyle: "disc" }}>
      <li>
        Saklama süresi doldu ise ya da mevzuattan dolayı saklama koşullarında değişiklik nedeni ile zorunluluk ortadan
        kalktı ise,
      </li>
      <li>İşleme amacı ortadan kalktı ise,</li>
      <li>Kanun’un 5. ve 6. maddelerindeki kişisel verilerin işlenmesini gerektiren şartlar ortadan kalktı ise,</li>
      <li>İlgili kişinin açık rızası ile işlenen kişisel verilerde ilgili kişi açık rızasını geri aldı ise,</li>
      <li>İlgili kişinin KVKK m.11 kapsamında yaptığı başvuru kabul edildi ise,</li>
      <li>
        Veri sorumlusu, ilgili kişi tarafından kişisel verilerinin silinmesi, yok edilmesi veya anonim hale getirilmesi
        talebi ile kendisine yapılan başvuruyu reddetmiş ise, verdiği cevap ilgili kişi tarafından yetersiz bulundu ise
        veya Kanun’da öngörülen süre içinde cevap verilmedi ise; Kurul’a şikâyette bulunulması ve Kurul tavsiyesi ile,
      </li>
      <li>Kişisel verilerin saklanmasını gerektiren azami süre geçmiş ise,</li>
    </ol>
    <p>silinir ve imha edilir.</p>

    <h3 className="h3 h3-responsive mt-5">D. SAKLAMA VE İMHA SÜRELERİ</h3>
    <p>
      Saklama ve imha süreleri belirlenirken MÜZFED sayılı yasa ve Yönetmelik kapsamında aşağıda yer alan kriterleri
      değerlendirir.
    </p>

    <ol style={{ listStyle: "disc" }}>
      <li>İlgili sektörde genel teamül gereği kabul edilen süre,</li>
      <li>İşlemeyi gerekli kılan ve ilgili kişi ile tesis edilen hukuki ilişkinin devam edeceği süre,</li>
      <li>
        Veri sorumlusunun elde edeceği meşru menfaatin hukuka ve dürüstlük kurallarına uygun olarak geçerli olacağı
        süre,
      </li>
      <li>Saklamanın yaratacağı risk, maliyet ve sorumlulukların hukuken devam edeceği süre,</li>
      <li>
        Belirlenecek azami sürenin ilgili veri kategorisinin doğru ve gerektiğinde güncel tutulmasına elverişli olup
        olmadığı,
      </li>
      <li>
        Veri sorumlusunun hukuki yükümlülüğü gereği ilgili veri kategorisinde yer alan kişisel verileri saklamak zorunda
        olduğu süre,
      </li>
      <li>Kişisel veriye bağlı bir hakkın ileri sürülmesi için belirlenen zamanaşımı süresi.</li>
    </ol>
    <p>
      Saklama süresi ortadan kalkan kişisel veriler, imha süreleri göz önünde bulundurularak 6 aylık periyodlarla işbu
      Politika’da yer verilen usullere uygun olarak anonim hale getirilir, silinir veya imha edilir. Kişisel verilerin
      silinmesi, yok edilmesi ve anonim hale getirilmesiyle ilgili yapılan bütün işlemler kayıt altına alınır ve söz
      konusu kayıtlar, diğer hukuki yükümlülükler hariç olmak üzere en az 3 (üç) yıl süreyle saklanır.
    </p>

    <h3 className="h3 h3-responsive mt-5">
      E. KİŞİSEL VERİLERİN SAKLANMASI VE İMHASINA YÖNELİK İDARİ VE TEKNİK TEDBİRLER
    </h3>

    <h5 className="h5 h5-responsive mt-4">İdari Tedbirler:</h5>
    <p>MÜZFED idari tedbirler kapsamında;</p>

    <ol style={{ listStyle: "disc" }}>
      <li>
        Saklanan kişisel verilere meslek birliği içi erişimi iş tanımı gereği erişmesi gerekli personel ile
        sınırlandırır. Erişimin sınırlandırılmasında verinin özel nitelikli olup olmadığı ve önem derecesi de dikkate
        alınır.
      </li>
      <li>
        İşlenen kişisel verilerin hukuka aykırı yollarla başkaları tarafından elde edilmesi hâlinde, bu durumu en kısa
        sürede ilgilisine ve Kurul’a bildirir.
      </li>
      <li>
        Kişisel verilerin paylaşılması ile ilgili olarak, kişisel verilerin paylaşıldığı kişiler ile kişisel verilerin
        korunması ve veri güvenliğine ilişkin çerçeve sözleşme imzalar yahut mevcut sözleşmesine eklenen hükümler ile
        veri güvenliğini sağlar.
      </li>
      <li>
        Kişisel verilerin işlenmesi hakkında bilgili ve deneyimli personel istihdam eder ve personeline kişisel
        verilerin korunması mevzuatı ve veri güvenliği kapsamında gerekli eğitimleri verir.
      </li>
      <li>
        Kendi tüzel kişiliği nezdinde kanun hükümlerinin uygulanmasını sağlamak amacıyla gerekli denetimleri yapar ve
        yaptırır. Denetimler sonucunda ortaya çıkan gizlilik ve güvenlik zafiyetlerini giderir.
      </li>
      <li>
        Kişisel verilerin bulunduğu ortama göre yeterli güvenlik önlemlerinin (elektrik kaçağı, yangın, su baskını,
        hırsızlık vb. durumlara karşı) alınmasını sağlar ve bu ortamlara yetkisiz giriş çıkışları engeller.
      </li>
    </ol>

    <h5 className="h5 h5-responsive mt-1">Teknik Tedbirler:</h5>
    <p>MÜZFED teknik tedbirler kapsamında;</p>

    <ol style={{ listStyle: "disc" }}>
      <li>
        Sızma (Penetrasyon) testleri ile meslek birliklerinin bilişim sistemlerine yönelik risk, tehdit, zafiyet ve
        varsa açıklıklar ortaya çıkarılarak gerekli önlemler alınmaktadır. Bilgi güvenliği olay yönetimi ile gerçek
        zamanlı yapılan analizler sonucunda bilişim sistemlerinin sürekliliğini etkileyecek riskler ve tehditlere karşı
        önlemler alınmaktadır.
      </li>
      <li>
        Çevresel tehditlere karşı bilişim sistemleri güvenliğinin sağlanması için, donanımsal (sistem odasına sadece
        yetkili personelin girişini sağlayan erişim kontrol sistemi, 7/24 çalışan izleme sistemi, yerel alan ağını
        oluşturan kenar anahtarların fiziksel güvenliğinin sağlanması, yangın söndürme sistemi, iklimlendirme sistemi
        vb.) ve yazılımsal (güvenlik duvarları, atak önleme sistemleri, ağ erişim kontrolü, zararlı yazılımları
        engelleyen sistemler vb.) önlemler alınmaktadır.
      </li>
      <li>
        Kişisel verilerin hukuka aykırı işlenmesini önlemeye yönelik riskler belirlenmekte, bu risklere uygun teknik
        tedbirlerin alınması sağlanmakta ve alınan tedbirlere yönelik teknik kontroller yapılmaktadır.
      </li>
      <li>
        Kurum içerisinde erişim prosedürleri oluşturularak kişisel verilere erişim ile ilgili raporlama ve analiz
        çalışmaları yapılmaktadır.
      </li>
      <li>
        Kişisel verilerin bulunduğu saklama alanlarına erişimler kayıt altına alınarak uygunsuz erişimler veya erişim
        denemeleri kontrol altında tutulmaktadır. Kurum, silinen kişisel verilerin ilgili kullanıcılar için erişilemez
        ve tekrar kullanılamaz olması için gerekli tedbirleri almaktadır.
      </li>
      <li>
        Kişisel verilerin hukuka aykırı olarak başkaları tarafından elde edilmesi halinde bu durumu ilgili kişiye ve
        Kurula bildirmek için Kurum tarafından buna uygun bir sistem ve altyapı oluşturulmuştur.
      </li>
      <li>
        Güvenlik açıkları takip edilerek uygun güvenlik yamaları yüklenmekte ve bilgi sistemleri güncel halde
        tutulmaktadır.
      </li>
      <li>Kişisel verilerin işlendiği elektronik ortamlarda güçlü parolalar kullanılmaktadır.</li>
      <li>
        Kişisel verilerin işlendiği elektronik ortamlarda güvenli kayıt tutma (loglama) sistemleri kullanılmaktadır.
        Kişisel verilerin güvenli olarak saklanmasını sağlayan veri yedekleme programları kullanılmaktadır.
      </li>
      <li>
        Özel nitelikli kişisel verilerin güvenliğine yönelik ayrı politika belirlenmiştir. Özel nitelikli kişisel veri
        işleme süreçlerinde yer alan çalışanlara yönelik özel nitelikli kişisel veri güvenliği konusunda eğitimler
        verilmiş, gizlilik sözleşmeleri yapılmış, verilere erişim yetkisine sahip kullanıcıların yetkileri
        tanımlanmıştır. Özel nitelikli kişisel verilerin işlendiği, muhafaza edildiği ve/veya erişildiği elektronik
        ortamlar kriptografik yöntemler kullanılarak muhafaza edilmekte, kriptografik anahtarlar güvenli ortamlarda
        tutulmakta, tüm işlem kayıtları loglanmakta, ortamların güvenlik güncellemeleri sürekli takip edilmekte, gerekli
        güvenlik testlerinin düzenli olarak yapılması/yaptırılması, test sonuçlarının kayıt altına alınması, özel
        nitelikli kişisel verilerin işlendiği, muhafaza edildiği ve/veya erişildiği fiziksel ortamların yeterli güvenlik
        önlemleri alınmakta, fiziksel güvenliği sağlanarak yetkisiz giriş çıkışlar engellenmektedir. Özel nitelikli
        kişisel veriler e-posta yoluyla aktarılması gerekiyorsa şifreli olarak kurumsal e-posta adresiyle veya KEP
        hesabı kullanılarak aktarılmaktadır.
      </li>
      <li>
        Taşınabilir bellek, CD, DVD gibi ortamlar yoluyla aktarılması gerekiyorsa kriptografik yöntemlerle şifrelenmekte
        ve kriptografik anahtar farklı ortamda tutulmaktadır. Farklı fiziksel ortamlardaki sunucular arasında aktarma
        gerçekleştiriliyorsa, sunucular arasında VPN kurularak veya sFTP yöntemiyle veri aktarımı
        gerçekleştirilmektedir. Kağıt ortamı yoluyla aktarımı gerekiyorsa evrakın çalınması, kaybolması ya da yetkisiz
        kişiler tarafından görülmesi gibi risklere karşı gerekli önlemler alınmakta ve evrak “gizli” formatta
        gönderilmektedir.
      </li>
    </ol>

    <h3 className="h3 h3-responsive mt-5">F. KAYIT ORTAMI</h3>
    <h5 className="h5 h5-responsive mt-4">Elektronik Ortamlar</h5>
    <p>(Etki alanı, yedekleme, e-posta, veritabanı, web, dosya paylaşım, vb.)</p>
    <p>
      Yazılımlar (ofis yazılımları, portal, bilgi güvenliği cihazları güvenlik duvarı, saldırı tespit ve engelleme,
      günlük kayıt dosyası, antivirüs vb.), kişisel bilgisayarlar, mobil cihazlar (telefon, tablet vb.) optik diskler
      (CD, DVD vb.), çıkartılabilir bellekler (USB, Hafıza Kart vb.) yazıcı, tarayıcı, fotokopi makinesi.
    </p>
    <h5 className="h5 h5-responsive">Elektronik Olmayan Ortamlar Sunucular</h5>
    <p>Manuel veri kayıt sistemleri (başvuru formları, üye defteri vs.) yazılı, basılı, görsel ortamlar, kağıt.</p>

    <h3 className="h3 h3-responsive mt-5">F. KİŞİSEL VERİLERİ KORUMA BİRİMİNİN GÖREV VE YETKİLERİ</h3>
    <p>
      Kişisel Verileri Koruma Birimi, kişisel verilerin korunması ile ilgili politika ve diğer bilgilendirmeleri
      birimlere duyurur ve birimlerin bu konuda gelişimlerini takip eder. Periyodik olarak eğitim süreçlerini planlar ve
      denetimleri yaptırır. Konu ile ilgili mevzuat değişikliklerini takip eder; politika ve metinlerin mevzuata göre
      güncellenmesini sağlar. Kurul kararlarını düzenli olarak takip eder.
    </p>

    <h3 className="h3 h3-responsive mt-5">H. POLİTİKANIN YÜRÜRLÜĞE GİRMESİ, İHLAL DURUMLARI VE YAPTIRIMLAR</h3>
    <ol>
      <li>
        İşbu Politika tüm çalışanlara duyurularak yürürlüğe girecek ve yürürlüğü itibariyle tüm iş birimleri,
        danışmanlar, dış hizmet sağlayıcıları ve kişisel veri işleyen herkes için bağlayıcı olacaktır.
      </li>
      <li>
        Politikaya aykırı hareket edenler olduğunda ilgili birim amiri doğrudan veri sorumlusunu ve veri sorumlusunun
        atadığı irtibat kişisini bilgilendirir ve politikanın uygulanmasını sağlamak üzere gerekli tedbirleri alır.
      </li>
      <li>Politikaya aykırı davranış hakkında Kişisel Verileri Koruma Birimi de bilgilendirilir.</li>
      <li>
        Politikaya aykırı davrananlar hakkında gerekli işlem kısa zamanda yapılır. Kişisel veriler Politika’nın 4.
        maddesinde belirtilen hususlar dikkate alınarak aşağıdaki tabloda belirtilen süreler boyunca saklanarak, süre
        sonunda ise anonim hale getirilecek, imha edilecek veya yok edilecektir.
      </li>
    </ol>

    <Table responsive bordered>
      <thead>
        <tr>
          <th>Süreç</th>
          <th>Saklama Süresi</th>
          <th>İmha Süresi</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>İş Kanunu kapsamında saklanılan veriler (örn. performans kayıtları vs.)</td>
          <td>İş ilişkisinin sona ermesine müteakip 5 yıl</td>
          <td>Saklama süresinin bitimini takiben 6 ay içerisinde</td>
        </tr>
        <tr>
          <td>İş sağlığı ve güvenliği mevzuatı kapsamında toplanan veriler (sağlık raporları vs.)</td>
          <td>İş ilişkisinin sona ermesine müteakip 15 yıl</td>
          <td>Saklama süresinin bitimini takiben 6 ay içerisinde</td>
        </tr>
        <tr>
          <td>SGK mevzuatı kapsamında tutulan veriler</td>
          <td>İş ilişkisinin sona ermesine müteakip 10 yıl</td>
          <td>Saklama süresinin bitimini takiben 6 ay içerisinde</td>
        </tr>
        <tr>
          <td>İş kazası/meslek hastalığına ilişkin bir talepte/davada kullanılabilecek dökümanlar</td>
          <td>İş ilişkisinin sona ermesine müteakip 10 yıl</td>
          <td>Saklama süresinin bitimini takiben 6 ay içerisinde</td>
        </tr>
        <tr>
          <td>Sair ilgili mevzuat gereği toplanan veriler</td>
          <td>İlgili mevzuatta öngörülen süre kadar</td>
          <td>Saklama süresinin bitimini takiben 6 ay içerisinde</td>
        </tr>
        <tr>
          <td>
            İlgili kişisel verinin Türk Ceza Kanunu veya sair ceza hükmü getiren mevzuat kapsamında bir suça konu olması
          </td>
          <td>Dava zaman aşımı müddetince</td>
          <td>Saklama süresinin bitimini takiben 6 ay içerisinde</td>
        </tr>
        <tr>
          <td>Sağlık Raporu</td>
          <td>İş ilişkisinin sona ermesini müteakip 10 yıl</td>
          <td>Saklama süresinin bitimini takiben 6 ay içerisinde</td>
        </tr>
        <tr>
          <td>
            Veri sorumlusunun tüm sözleşme ilişkilerinden dolayı topladığı kişisel veriler (Sözleşme ilişkisi devam
            ettiği sürece 10 yıllık süre uzayabilir.)
          </td>
          <td>Kayıt altına alınmasına müteakip 10 yıl</td>
          <td>Saklama süresinin bitimini takiben 6 ay içerisinde</td>
        </tr>
        <tr>
          <td>Çalışan adaylarının CV ve iş başvuru formları</td>
          <td>Kayıt altına alınmasına müteakip 2 yıl</td>
          <td>Saklama süresinin bitimini takiben 6 ay içerisinde</td>
        </tr>
        <tr>
          <td>
            Çalışanlara ait iş başvuru formları, eğitim ve performans kayıtları, sağlık raporları, adli sicil kayıtları,
            tüm sunulu belgeler
          </td>
          <td>İş ilişkisinin sona ermesinden itibaren 10 yıl</td>
          <td>Saklama süresinin bitimini takiben 6 ay içerisinde</td>
        </tr>
      </tbody>
    </Table>
    <p>
      Yukarıda düzenlenen sürelerden daha uzun süre saklanması gereken veriler ile ilgili olarak meslek birliği
      haklarını, meşru menfaatleri ve kanundan kaynaklı tutma süreleri/ değişimi nedeni ile saklı tutar ve ilgili
      kişinin talebi halinde gerekçesi ile birlikte silinme koşullarını açıklar. Süresi geçen kişisel veriler resen imha
      edilecek ve imha edildiğine dair kayıtlar 3 yıl saklanacaktır. İmha yöntemi en uygun yöntem olacak şekilde Veri
      Sorumlusu tarafından her kişisel verinin niteliğine göre belirlenecektir.
    </p>
  </>
);

export default DisposalPolicyText;
