import faqData from "@muzfed/data/faq-data";
import { AccordionBody, AccordionHeader, AccordionItem, Col, Container, Row, UncontrolledAccordion } from "reactstrap";

const Faq = () => (
  <div className="sd-accordio-area pt-30 pb-30">
    <Container>
      <Row>
        <Col xl={12}>
          <UncontrolledAccordion defaultOpen="1">
            {faqData.map((item) => {
              const { id, title, desc } = item;
              return (
                <AccordionItem>
                  <AccordionHeader targetId={id}>{title}</AccordionHeader>
                  <AccordionBody accordionId={id}>{desc}</AccordionBody>
                </AccordionItem>
              );
            })}
          </UncontrolledAccordion>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Faq;
