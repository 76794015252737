import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const Tariffs = () => (
  <div className="ac-about-content-area pb-30 pt-30">
    <Container>
      <Row>
        <Col className="wow tpfadeLeft" data-wow-duration=".5s" data-wow-delay=".7s">
          <div className="ac-about-left">
            <h5 className="mb-4">2024 Tarifesi</h5>
            <Link
              className="text-primary"
              target="_blank"
              download={true}
              to={process.env.PUBLIC_URL + "/assets/docs/MUZFED_2024_YILI_TARIFESI.pdf"}
            >
              2024 Müzik Federasyonları Tarifesini İndirmek İçin Tıklayınız
            </Link>

            <hr />

            <h5 className="mb-4">2023 Tarifesi</h5>
            <Link
              className="text-primary"
              target="_blank"
              download={true}
              to={process.env.PUBLIC_URL + "/assets/docs/MUZFED_2023_YILI_TARIFESI.pdf"}
            >
              2023 Müzik Federasyonları Tarifesini İndirmek İçin Tıklayınız
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Tariffs;
