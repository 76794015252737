import { Col, Container, Row, Table } from "reactstrap";

const BoardOfDirectors = () => {
  return (
    <Container className="pt-30 pb-30">
      <Row>
        <Col className="wow tpfadeLeft" data-wow-duration=".5s" data-wow-delay=".7s">
          <div className="ac-about-left">
            <h5>Yönetim Kurulu</h5>
            <Table size="small" responsive bordered>
              <tbody>
                <tr>
                  <td width="50%" className="fw-bold">
                    YÖNETİM KURULU BAŞKANI
                  </td>
                  <td width="50%">BÜLENT FORTA</td>
                </tr>
                <tr>
                  <td width="50%" className="fw-bold">
                    YÖNETİM KURULU BAŞKAN YARDIMCISI
                  </td>
                  <td width="50%">BURHAN ŞEŞEN</td>
                </tr>
                <tr>
                  <td width="50%" className="fw-bold">
                    YÖNETİM KURULU SAYMAN ÜYESİ
                  </td>
                  <td width="50%">MUSTAFA DEMİR</td>
                </tr>
                <tr>
                  <td width="50%" className="fw-bold">
                    YÖNETİM KURULU ÜYESİ
                  </td>
                  <td width="50%">BÜLENT SEYHAN</td>
                </tr>
                <tr>
                  <td width="50%" className="fw-bold">
                    YÖNETİM KURULU ÜYESİ
                  </td>
                  <td width="50%">MEHMET GÜNAY</td>
                </tr>
                <tr>
                  <td width="50%" className="fw-bold">
                    YÖNETİM KURULU ÜYESİ
                  </td>
                  <td width="50%">EŞREF ZİYA TERZİ</td>
                </tr>
                <tr>
                  <td width="50%" className="fw-bold">
                    YÖNETİM KURULU ÜYESİ
                  </td>
                  <td width="50%">MEHMET GÜNEŞ</td>
                </tr>
              </tbody>
            </Table>

            <h5>Genel Sekreter</h5>
            <Table responsive size="small" bordered>
              <tbody>
                <tr>
                  <td width="50%" className="fw-bold">
                    GENEL SEKRETER
                  </td>
                  <td width="50%">AHMET TÜRKOĞLU</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default BoardOfDirectors;
