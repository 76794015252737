import { Link } from "react-router-dom";

const TermsOfUseText = () => (
  <>
    <h3 className="h3 h3-responsive text-center mb-5">KULLANIM KOŞULLARI</h3>
    <p>
      <Link rel="noopener noreferrer" to="https://muzfed.org" target="_blank" style={{ textDecoration: "underline" }}>
        https://muzfed.org
      </Link>{" "}
      uzantılı internet sitesinde (bundan sonra kısaca "İNTERNET SİTESİ" denilecektir.) sunulan hizmetler MÜZFED Müzik
      Sektöründe Bağlantılı Haklar Federasyonu (bundan böyle kısaca, "MÜZFED" olarak anılacaktır) tarafından müştereken
      sağlanmaktadır. "İNTERNET SİTESİ"nin yasal sahibi MÜZFED olup, "İNTERNET SİTESİ" üzerinde her türlü kullanım ve
      tasarruf yetkisi MÜZFED'e aittir
    </p>

    <p>
      İşbu kullanım koşullarını{" "}
      <Link rel="noopener noreferrer" to="https://muzfed.org" target="_blank" style={{ textDecoration: "underline" }}>
        https://muzfed.org
      </Link>{" "}
      uzantılı internet sitesi adına MÜZFED gerektiği zaman değiştirebilir; ancak bu değişiklikler düzenli olarak
      "İNTERNET SİTESİ"nde yayınlanacak ve aynı tarihten itibaren geçerli olacaktır.
    </p>

    <p>
      SİTE hizmetlerinden yararlanan ve "İNTERNET SİTESİ"ne erişim sağlayan her gerçek ve tüzel kişi, MÜZFED tarafından
      işbu kullanım koşulları hükümlerinde yapılan her değişikliği, önceden kabul etmiş sayılmaktadır.
    </p>

    <p>
      <Link rel="noopener noreferrer" to="https://muzfed.org" target="_blank" style={{ textDecoration: "underline" }}>
        https://muzfed.org
      </Link>{" "}
      uzantılı internet sitesi adına MÜZFED, bu "İNTERNET SİTESİ"nde yer alan veya alacak olan bilgileri, formları ve
      içeriği dilediği zaman değiştirme hakkını saklı tutmaktadır
    </p>

    <h3 className="h3 h3-responsive mt-5">Sözleşme Tanımları</h3>

    <p>
      <Link rel="noopener noreferrer" to="https://muzfed.org" target="_blank" style={{ textDecoration: "underline" }}>
        https://muzfed.org
      </Link>{" "}
      uzantılı internet sitesi: MÜZFED'in, kullacıların müzik lisansına daha kolay ve tek elden ulaşmasını sağlamak,
      otellerin sorunlarına daha kısa yoldan çözümler bulmak için bir arada kurup müştereken sahip oldukları "İNTERNET
      SİTESİ" dir.
    </p>

    <p>
      <b>İNTERNET SİTESİ:</b>{" "}
      <Link rel="noopener noreferrer" to="https://muzfed.org" target="_blank" style={{ textDecoration: "underline" }}>
        https://muzfed.org
      </Link>{" "}
      uzantılı internet sitesidir.
    </p>

    <p>
      <b>ÜYE:</b> MÜZFED üyeleri meslek birliklerini ifade etmektedir.
    </p>

    <p>
      <b>KULLANICI:</b>{" "}
      <Link rel="noopener noreferrer" to="https://muzfed.org" target="_blank" style={{ textDecoration: "underline" }}>
        https://muzfed.org
      </Link>{" "}
      uzantılı internet sitesini ziyaret eden ve kullanan kişidir.
    </p>

    <p>
      <b>LİNK:</b> : "İNTERNET SİTESİ" üzerinden bir başka internet sitesine, dosyalara, içeriğe veya başka bir internet
      sitesinden ""İNTERNET SİTESİ"ne, dosyalara ve içeriğe erişimi mümkün kılan bağlantıdır.
    </p>

    <p>
      <b>İÇERİK:</b> "İNTERNET SİTESİ"nde ve/veya herhangi bir başka internet sitesinde yayınlanan veya erişimi mümkün
      olan her türlü bilgi, dosya, resim, program, rakam, fiyat, vs. görsel, yazınsal ve işitsel imgelerdir.
    </p>

    <p>
      <b>İNTERNET SİTESİ KULLANIM KOŞULLARI:</b> "İNTERNET SİTESİ" vasıtasıyla sunulan genel veya kişiye özel
      nitelikteki hizmetlerden yararlanacak gerçek ve/veya tüzel kişilerle ONLINE LİSANSLAMA BİRİMİ arasında elektronik
      ortamda akdedilen işbu sözleşmedir
    </p>

    <p>
      <b>KİŞİSEL BİLGİ:</b> KULLANICILARIN kimliği, adresi, elektronik posta adresi, telefon numarası, IP adresi, kredi
      kartı bilgileri, konum, adres ve iletişim bilgileri veya "İNTERNET SİTESİ"nin hangi bölümlerini ziyaret ettiği,
      domain tipi, browser tipi, ziyaret tarihi, saati, vs. bilgilerdir.
    </p>

    <p>
      Hizmetlerin Kapsamı, "İNTERNET SİTESİ" üzerinden müzik içeriği kullanan işletme ve kuruluşlara, 5846 sayılı Fikir
      ve Sanat Eserleri Kanunu mevzuatı kapsamında tarafından online olarak yazılı izin ve sertifika verilmesine ilişkin
      hizmetlerin sunulması ve ilişki faaliyetlerden ibarettir.
    </p>

    <p>
      "İNTERNET SİTESİ" üzerinden sunacağı hizmetlerin kapsamını ve niteliğini belirlemekte tamamen serbest olup,
      hizmetlere ilişkin olarak yapacağı değişiklikleri "İNTERNET SİTESİ" nde yayınlamasıyla yürürlüğe koymuş addedilir.
    </p>

    <p>
      <b>İNTERNET SİTESİ</b>,{" "}
      <Link rel="noopener noreferrer" to="https://muzfed.org" target="_blank" style={{ textDecoration: "underline" }}>
        https://muzfed.org
      </Link>{" "}
      uzantılı internet sitesini ifade eder ve bu "İNTERNET SİTESİ" bünyesinde sunulacak hizmetlerden yararlanabilmek
      için "İNTERNET SİTESİ""nin ilgili bölümünün içeriğinde belirtilecek özellikleri belirlemekte tamamen serbest olup,
      özelliklere ilişkin olarak yapacağı değişikliklerin "İNTERNET SİTESİ"nde yayınlamasıyla yürürlüğe koymuş
      addedilir. Genel Hükümler "İNTERNET SİTESİ" üzerinden, kendi kontrolünde olmayan ve başkaca üçüncü kişilerin sahip
      olduğu ve işlettiği başka internet sitelerine ve/veya başka içeriklere link verilebilir. Bu linkler KULLANICI"lara
      ve ÜYE"lere yönlenme kolaylığı sağlamak amacıyla konmuş olup, herhangi bir internet sitesini veya o siteyi işleten
      kişiyi desteklememektedir. Link verilen internet sitesinin içerdiği bilgilere yönelik herhangi bir türde bir beyan
      veya garanti niteliği taşımamaktadır. "İNTERNET SİTESİ" üzerindeki linkler vasıtasıyla erişilen web siteleri ve
      içerikleri hakkında MÜZFED'in herhangi bir sorumluluğu yoktur ve bu internet sitelerin kullanımıyla doğabilecek
      zararlar, KULLANICI"ların ve ÜYE"lerin kendi sorumluluğundadır. MÜZFED'in bu tür link verilen web sitelerine
      erişimi, kendi yazılı muvafakatine bağlayabilir.
    </p>

    <p>
      <Link rel="noopener noreferrer" to="https://muzfed.org" target="_blank" style={{ textDecoration: "underline" }}>
        https://muzfed.org
      </Link>
    </p>

    <p>
      <b>KULLANICILAR</b>, "İNTERNET SİTESİ"ndeki, yüklenmeye (download) ve/veya paylaşıma müsait dosya, bilgi ve
      belgelerin, virüslerden, wormlardan, truva atlarından, dialer programlarından spam, spyware veya bunlar gibi diğer
      her türlü kötü ve zarar verme amaçlı kodlardan veya materyallerden arındırılamamış olabileceğini ve bu hususlarda
      "İNTERNET SİTESİ"nin garanti vermediğini kabul etmektedir. Bu tip kötü ve zarar verme amaçlı programların,
      kodların veya materyallerin önlenmesi, veri giriş-çıkışlarının doğruluğu veya herhangi bir kayıp verinin geri
      kazanılması için gereken tüm yazılım ve donanım ihtiyaçlarını karşılamak, bakım ve güncellemelerini yapmak tamamen
      KULLANICI"nın ve ÜYE"nin sorumluluğundadır. Bu tür kötü amaçlı programlar, kodlar veya materyallerin sebep
      olabileceği, veri yanlışlıkları veya kayıplarından dolayı KULLANICI ve ÜYE"nin veya üçüncü kişilerin
      uğrayabileceği hiçbir zarardan MÜZFED sorumlu değildir. Bu tür kötü amaçlı programlar, kodlar veya materyaller,
      veri yanlışlıkları veya kayıplarından dolayı KULLANICI"nın ve ÜYE"nin veya üçüncü kişilerin uğrayabileceği hiçbir
      zarardan MÜZFED sorumlu değildir.
    </p>

    <p>
      MÜZFED, işbu "İNTERNET SİTESİ" ve "İNTERNET SİTESİ" uzantısında mevcut her tür hizmet, ürün, kampanya, vs.
      bilgiler ve "İNTERNET SİTESİ"yi kullanma koşulları ile SİTE"de sunulan bilgileri önceden bir ihtara gerek
      olmaksızın değiştirme, "İNTERNET SİTESİ"yi ve içeriğini yeniden düzenleme, yayını durdurma ve/veya duraklatma
      hakkını saklı tutar. Değişiklikler, "İNTERNET SİTESİ"nde yayınlanmalarıyla yürürlüğe girerler. "İNTERNET
      SİTESİ"nin kullanımı ya da "İNTERNET SİTESİ"ne giriş ile bu değişiklikler de kabul edilmiş sayılır. Bu koşullar
      link verilen diğer internet sayfaları için de geçerlidir. MÜZFED, sözleşmenin ihlali, haksız fiil, ihmal veya
      diğer sebepler neticesinde; işlemin kesintiye uğraması, hata, ihmal, kesinti, silinme, kayıp, işlemin veya
      iletişimin gecikmesi, bilgisayar virüsü, iletişim hatası, hırsızlık, imha veya izinsiz olarak kayıtlara girilmesi,
      değiştirilmesi veya kullanılması hususunda ve durumunda herhangi bir sorumluluk kabul etmez.
    </p>

    <p>
      "İNTERNET SİTESİ" dâhilinde üçüncü kişiler tarafından sağlanan hizmetlerden ve yayınlanan içeriklerden dolayı
      MÜZFED'in, iş birliği içinde bulunduğu kurumların, MÜZFED çalışanlarının ve yöneticilerinin, sorumluluğu
      bulunmamaktadır.
    </p>

    <p>
      Herhangi bir üçüncü kişi tarafından sağlanan ve yayınlanan bilgilerin, içeriklerin, görsel ve işitsel imgelerin
      doğruluğu ve hukuka uygunluğunun taahhüdü bütünüyle bu eylemleri gerçekleştiren üçüncü kişilerin
      sorumluluğundadır. MÜZFED, üçüncü kişiler tarafından sağlanan hizmetlerin ve içeriklerin güvenliğini, doğruluğunu
      ve hukuka uygunluğunu taahhüt etmemektedir.
    </p>

    <p>
      "İNTERNET SİTESİ"ni kullananlar, yalnızca hukuka uygun ve şahsi amaçlarla SİTE üzerinde işlem yapabilirler.
      KULLANICI"ların ve ÜYE"lerin, "İNTERNET SİTESİ" dâhilinde yaptığı her işlem ve eylemdeki hukuki ve cezai
      sorumlulukları kendilerine aittir. Her KULLANICI ve her ÜYE, MÜZFED ve/veya başka bir üçüncü kişinin haklarına
      tecavüz teşkil edecek nitelikteki herhangi bir faaliyette bulunmayacağını taahhüt eder. KULLANICI ve ÜYE"lerin
      "İNTERNET SİTESİ" üzerindeki faaliyetleri nedeniyle üçüncü kişilerin uğradıkları veya uğrayabilecekleri
      zararlardan dolayı MÜZFED"nin doğrudan ve/veya dolaylı hiçbir sorumluluğu yoktur.
    </p>

    <p>
      İşbu "İNTERNET SİTESİ" nin sahibi müştereken MÜZFED'dir. Bu "İNTERNET SİTESİ"nde bulunan bilgiler, yazılar,
      resimler, markalar, slogan ve diğer işaretler ile sair sınaî ve fikri mülkiyet haklarına ilişkin bilgilerin
      korunmasına yönelik programlarla, sayfa düzeni ve "İNTERNET SİTESİ" nin sunumu MÜZFED'in ya da MÜZFED'in izin ve
      lisans aldığı kuruluşların mülkiyetindedir. İşbu "İNTERNET SİTESİ"ndeki bilgilerin ya da "İNTERNET SİTESİ"
      sayfalarına ilişkin her tür veri tabanı, internet sitesi, software-code"ların html kodu ve diğer kodlar vs. ile
      "İNTERNET SİTESİ" içeriğinde bulunan ürünlerin, tasarımların, resimlerin, metinlerin, görsel, işitsel vesaire
      imgelerin, video kliplerin, dosyaların, katalogların ve listelerin kısmen ya da tamamen kopyalanması,
      değiştirilmesi, yayınlanması, online ya da diğer bir medya kullanılmak suretiyle gönderimi, dağıtımı, satılması
      yasaktır. KULLANICI ve ÜYE, yukarıda sayılan ve bunlarla sınırlı olmayan "İNTERNET SİTESİ" yazılım, donanım ve
      içeriğini çoğaltmayacağını, kopyalamayacağını, dağıtmayacağını, işlemeyeceğini gerek bu eylemleri ile gerekse de
      başka yollarla MÜZFED ile doğrudan ve/veya dolaylı olarak rekabete girmeyeceğini kabul ve taahhüt etmektedir.
      KULLANICI ve ÜYE, MÜZFED hizmetlerini, MÜZFED bilgilerini ve MÜZFED"in telif haklarına tâbi çalışmalarını yeniden
      satmak, işlemek, paylaşmak, dağıtmak, sergilemek veya başkasının
      <Link rel="noopener noreferrer" to="https://muzfed.org" target="_blank" style={{ textDecoration: "underline" }}>
        https://muzfed.org
      </Link>{" "}
      hizmetlerine erişmesi veya kullanmasına izin vermek hakkına sahip değildir. Bu sayfadaki bilgilerin kısmen
      kopyalanması, basılması, işlenmesi, dağıtılması, çoğaltılması, sergilenmesi ancak ticari olmayan kişisel
      ihtiyaçlar için ve MÜZFED"in yazılı izni ile mümkündür.
    </p>

    <p>
      MÜZFED, "İNTERNET SİTESİ" üzerinden KULLANICI"lar ve ÜYE"ler tarafından kendisine iletilen bilgileri ,İnternet
      Sitesi Kullanım Koşulları“ hükümleri doğrultusunda kullanabilir. Bu bilgileri işleyebilir, bir veri tabanı
      üzerinde tasnif edip muhafaza edebilir. MÜZFED,{" "}
      <b>
        aynı zamanda; KULLANICI ve ÜYE"nin kimliği, adresi, elektronik posta adresi, telefon numarası, IP adresi,
        "İNTERNET SİTESİ" nin hangi bölümlerini ziyaret ettiği, domain tipi, browser tipi, ziyaret tarihi, saati vs
        bilgileri de istatistiki değerlendirme, kampanyaların duyurusunu yapma ve kişiye yönelik hizmetler sunma gibi
        amaçlarla kullanabilir.
      </b>{" "}
      KULLANICI"lara ve ÜYE"lere ait kişisel bilgiler, kanunla yetkili kılınan mercilerin talebi ve aşağıda sayılan
      haller hariç olmak üzere gerçek ve tüzel üçüncü kişilere açıklanmayacaktır. Kişisel bilgi ender olarak MÜZFED için
      veya onun adına davranan üçüncü taraflara veya MÜZFED'in işi ile ilgili olanlara verilerin özgün olarak kullanım
      amaçlarını daha iyi işlemek ya da KULLANICI"ların ve ÜYE"lerin önerdiği amaçlara uygun olarak daha iyi hizmet
      verebilmek üzere verilir.
    </p>

    <p>
      İşbu "İNTERNET SİTESİ" Kullanım Koşulları dâhilinde MÜZFED tarafından açıkça yetki verilmediği hallerde MÜZFED ;
      MÜZFED hizmetleri, MÜZFED bilgileri, MÜZFED telif haklarına tâbi çalışmaları, MÜZFED ticari markaları, MÜZFED
      ticari görünümü veya bu SİTE vasıtasıyla sağladığı başkaca varlık ve bilgilere yönelik tüm haklarını saklı
      tutmaktadır. MÜZFED , "İNTERNET SİTESİ"ne erişilmesi, "İNTERNET SİTESİ"nin ya da "İNTERNET SİTESİ"ndeki bilgilerin
      ve diğer verilerin programların vs. kullanılması sebebiyle, sözleşmenin ihlali, haksız fiil, ya da başkaca
      sebeplere binaen, doğabilecek doğrudan ya da dolaylı hiçbir zarardan sorumlu değildir. MÜZFED , sözleşmenin
      ihlali, haksız fiil, ihmal veya diğer sebepler neticesinde; işlemin kesintiye uğraması, hata, ihmal, kesinti
      hususunda herhangi bir sorumluluk kabul etmez. Bu "İNTERNET SİTESİ"ne ya da link verilen diğer internet sitelerine
      erişilmesi ya da "İNTERNET SİTESİ"nin kullanılması ile MÜZFED "nin, kullanım/ziyaret sonucunda, doğabilecek her
      tür sorumluluktan, mahkeme ve diğer masraflar da dahil olmak üzere her tür zarar ve talep hakkından ayrı kılındığı
      kabul edilmektedir.
    </p>

    <p>
      Devir: MÜZFED, bu sözleşmeyi bildirimsiz olarak istediği zaman kısmen veya bütünüyle devredebilir. Ancak KULLANICI
      ve ÜYE bu sözleşmeyi veya herhangi bir kısmını başka bir tarafa devredemez. Bu türden bir devir girişimi
      geçersizdir.
    </p>

    <h3 className="h3 h3-responsive mt-5">Mücbir Sebepler</h3>
    <p>
      Hukuken mücbir sebep sayılan tüm durumlarda, MÜZFED işbu, İNTERNET SİTESİ KULLANIM KOŞULLARI“nı geç ifa etmekten
      veya ifa etmemekten dolayı yükümlü değildir. Bu ve bunun gibi durumlar, MÜZFED açısından, gecikme veya ifa etmeme
      veya temerrüt addedilmeyecek veya bu durumlar için MÜZFED"nin herhangi bir tazminat yükümlülüğü doğmayacaktır.
      Uygulanacak Hukuk ve Yetki İşbu ,İNTERNET SİTESİ KULLANIM KOŞULLARI“ndan kaynaklanacak ihtilaflar Türk Hukuku"na
      tabidir ve İstanbul Merkez Mahkemeleri ve İcra Daireleri yetkilidir. MÜZFED’in KULLANICI ve ÜYE"nin bulunduğu
      ülkede dava açma hakkı saklıdır. Yürürlük ve Kabul İşbu ,İNTERNET SİTESİ KULLANIM KOŞULLARI“, "İNTERNET SİTESİ"
      içeriğinde ilan edildiği tarihte yürürlük kazanır. KULLANICI"lar ve ÜYE"ler, işbu sözleşme hükümlerini "İNTERNET
      SİTESİ"ni kullanmakla kabul etmiş olmaktadırlar. MÜZFED, dilediği zaman işbu sözleşme hükümlerinde değişikliğe
      gidebilir, değişiklikler, sürüm numarası ve değişiklik tarihi belirtilerek "İNTERNET SİTESİ" üzerinden yayınlanır
      ve aynı tarihte yürürlüğe girer.
    </p>
  </>
);

export default TermsOfUseText;
