import { Col, Container, Row } from "reactstrap";

const About = () => (
  <div className="ac-about-content-area pb-30 pt-30">
    <Container>
      <Row>
        <Col className="wow tpfadeLeft" data-wow-duration=".5s" data-wow-delay=".7s">
          <div className="ac-about-left">
            <p>
              MÜZFED, 5846 sayılı Fikir ve Sanat Eserleri kanununa göre kurulmuş olan Müyap, Müyorbir, Müyabir, Müzikbir
              ve TSMB meslek birliklerinin kurduğu müzik sektöründe fonogram yapımcıları ve icracıların haklarını
              koruyan federasyondur. MÜZFED’in tüm iş ve işlemleri Kültür ve Turizm Bakanlığı’nın idari ve mali denetimi
              altındadır. MÜZFED, konaklama tesislerinin Müzik Lisans Belgesi’ne daha kolay ve tek elden ulaşmasını
              sağlamak, işletmelerin sorunlarına daha kısa yoldan çözümler bulmak için MSF ile bir araya gelerek Online
              Lisanslama birimini kurdu. Online Lisanslama birimi vasıtasıyla hem MÜZFED hem de MSF’den tek tek Müzik
              Lisans Sertifikası almanıza gerek kalmamış ve çok daha kolay biçimde yasal müzik kullanımınız
              sağlanmıştır.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default About;
