import useSticky from "@muzfed/hooks/useSticky";
import React from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import NavMenus from "./NavMenus";
import Sidebar from "./Sidabar";
import MobileMenu from "./MobileMenu";

const Header: React.FC = () => {
  const { headerSticky } = useSticky();
  const [sidebarOpen, setSidebarOpen] = React.useState<boolean>(false);

  return (
    <>
      <header className="d-none d-lg-block">
        <div
          id="header-sticky"
          className={`tp-header-area header-transparent pl-165 pr-165 pt-35 
        ${headerSticky ? "header-sticky" : ""}`}
        >
          <Container fluid>
            <div className="row align-items-center">
              <div className="col-xl-2 col-lg-2">
                <div className="tp-logo">
                  <Link to="/">
                    <img src={require("@muzfed/assets/img/logo/muzfed-logo.png")} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-9 col-lg-9">
                <div className="tp-main-menu">
                  <nav id="mobile-menu">
                    {/* nav menus start */}
                    <NavMenus />
                    {/* nav menus end */}
                  </nav>
                </div>
              </div>
              <div className="col-xl-1 col-lg-1">
                <div className="tp-menu-bar text-end" onClick={() => setSidebarOpen(true)}>
                  <button>
                    <i className="fal fa-bars"></i>
                  </button>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </header>

      {/* <!-- mobile-menu-area --> */}
      <MobileMenu />
      {/* <!-- mobile-menu-area-end --> */}

      {/* off canvas start */}
      <Sidebar isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
      {/* off canvas end */}
    </>
  );
};

export default Header;
