import usePageTitle from "@muzfed/hooks/usePageTitle";
import { LayoutProps } from "@muzfed/types";
import { animationCreate } from "@muzfed/utils/animation";
import React, { useEffect, useLayoutEffect } from "react";
import Breadcrumb from "./components/Breadcrumb";
import SubPageFooter from "./components/SubPageFooter";
import SubPageHeader from "./components/SubPageHeader";
import usePageHeader from "@muzfed/hooks/usePageHeader";
import { useLocation } from "react-router-dom";

const SubPageLayout: React.FC<LayoutProps> = ({ children }) => {
  const pageTitle = usePageTitle();
  const pageHeader = usePageHeader();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      animationCreate();
    }, 50);
  }, []);

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <SubPageHeader />
      <Breadcrumb title={pageTitle} pageHeader={pageHeader} />
      {children}
      <SubPageFooter />
    </>
  );
};

export default SubPageLayout;
