import menuData from "@muzfed/data/menu-data";
import { useState } from "react";
import { Link } from "react-router-dom";

interface SidebarProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, setIsOpen }) => {
  const [navTitle, setNavTitle] = useState("");

  const openMobileMenu = (menu: string) => {
    if (navTitle === menu) {
      setNavTitle("");
    } else {
      setNavTitle(menu);
    }
  };

  return (
    <>
      <div className="tp-offcanvas-area">
        <div className={`tpoffcanvas ${isOpen ? "opened" : ""}`}>
          <div className="tpoffcanvas__logo">
            <Link to="/">
              <img src={require("@muzfed/assets/img/logo/muzfed-logo-light.png")} alt="" />
            </Link>
          </div>
          <div className="tpoffcanvas__close-btn" onClick={() => setIsOpen(false)}>
            <button className="close-btn">
              <i className="fal fa-times-hexagon"></i>
            </button>
          </div>
          <div className="tpoffcanvas__content d-none d-sm-block">
            <p>MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU</p>
          </div>
          <div className="mobile-menu d-lg-none">
            <div className="mm-menu">
              <ul>
                {menuData.map((menu, i) =>
                  menu.show_on_menu === true ? (
                    <li
                      key={i}
                      className={
                        !menu.has_dropdown ? "" : navTitle === menu?.title ? "has-droupdown active" : "has-droupdown"
                      }
                    >
                      {menu.has_dropdown && <button onClick={() => openMobileMenu(menu.title)}>{menu.title} </button>}
                      <ul className={navTitle === menu?.title ? "sub-menu active" : "sub-menu"}>
                        {menu?.sub_menus?.map((sub, i) => (
                          <li key={i}>
                            <Link to={`${sub.link}`}>{sub.title}</Link>
                          </li>
                        ))}
                      </ul>
                      {!menu.has_dropdown && <Link to={menu.link}>{menu.title}</Link>}
                    </li>
                  ) : null
                )}
              </ul>
            </div>
          </div>
          <div className="tpoffcanvas__contact">
            <span>Bizimle İLetişime Geçin</span>
            <ul>
              <li>
                <i className="fas fa-star"></i>{" "}
                <Link to="#!" rel="noreferrer" target="_blank">
                  Kuloğlu Mah. Turnacıbaşı Cad. No:10 K:2
                  <br />
                  Beyoğlu / İstanbul
                </Link>
              </li>
              <li>
                <i className="fas fa-star"></i>
                <a href="tel:902129630813">0 (212) 243 82 14</a>
              </li>
              <li>
                <i className="fas fa-star"></i>
                <a href="mailto:Collaxmail@gmail.com">info@muzfed.org</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* overlay start */}
      <div onClick={() => setIsOpen(false)} className={`body-overlay ${isOpen ? "apply" : ""}`}></div>
      {/* overlay end */}
    </>
  );
};

export default Sidebar;
