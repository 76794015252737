import menuData from "@muzfed/data/menu-data";
import { useLocation } from "react-router-dom";

const usePageHeader = () => {
  const location = useLocation();
  let pageHeader = require("@muzfed/assets/img/page-header/page-header-note.jpg");

  if (typeof menuData.find((x) => x.link === location.pathname) !== "undefined") {
    const menu = menuData.find((x) => x.link === location.pathname);
    pageHeader =
      menu && menu.pageHeader ? menu.pageHeader : require("@muzfed/assets/img/page-header/page-header-note.jpg");
  }
  return pageHeader;
};

export default usePageHeader;
