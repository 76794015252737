import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const Members = () => (
  <div className="tp-contact-area pb-130">
    <Container>
      <Row className="mt-30">
        <Col>
          <div className="tp-contct-wrapper contact-space-40">
            <div className="tp-contact-info mb-40">
              <h3 className="contact-title mb-20">MÜZFED ÜYE İLETİŞİM BİLGİLERİ</h3>
              <div className="d-flex flex-row align-items-center justify-content-start mb-2">
                <Link to="https://tr.mu-yap.org" target="_blank">
                  <img
                    className="rounded img-responsive"
                    src={require("@muzfed/assets/img/logo/meslek-birlikleri/muyap.jpg")}
                    alt="MÜ-YAP"
                    width={100}
                  />
                </Link>
                <div className="h-100 w-100 ms-3">
                  <h4 className="contact-title">MÜ-YAP Bağlantılı Hak Sahibi Fonogram Yapımcıları Meslek Birliği</h4>
                  <p className="m-0">Kuloğlu Mah. Turnacıbaşı Cad. Saray Han No:10 K:5, 34433, Beyoğlu/İstanbul</p>
                </div>
              </div>

              <div className="d-flex flex-row align-items-center justify-content-start mb-2">
                <Link to="https://muyabir.org.tr/" target="_blank">
                  <img
                    className="rounded img-responsive"
                    src={require("@muzfed/assets/img/logo/meslek-birlikleri/muyabir.jpg")}
                    alt="Müyabir"
                    width={100}
                  />
                </Link>
                <div className="h-100 w-100 ms-3">
                  <h4 className="contact-title">MÜYABİR Bağlantılı Hak Sahibi Fonogram Yapımcıları Meslek Birliği</h4>
                  <p className="m-0">Merkez Efendi Mah. Mevlâna Cad. Tercüman Sitesi A-10 Blok Kat:8 Daire:36, 34015</p>
                </div>
              </div>

              <div className="d-flex flex-row align-items-center justify-content-start mb-2">
                <Link to="http://muzikbir.org/" target="_blank">
                  <img
                    className="rounded img-responsive"
                    src={require("@muzfed/assets/img/logo/meslek-birlikleri/muzbir.jpg")}
                    alt="MÜZİKBİR"
                    width={100}
                  />
                </Link>
                <div className="h-100 w-100 ms-3">
                  <h4 className="contact-title">MÜZİKBİR Bağlantılı Hak Sahibi Fonogram Yapımcıları Meslek Birliği</h4>
                  <p className="m-0">Gülbahar Mah. Büyükdere Cad. Gayret Apt. No:105 K:1, D:1, 34083 Şişli/İstanbul</p>
                </div>
              </div>

              <div className="d-flex flex-row align-items-center justify-content-start mb-2">
                <Link to="https://muyorbir.org.tr/" target="_blank">
                  <img
                    className="rounded img-responsive"
                    src={require("@muzfed/assets/img/logo/meslek-birlikleri/muyorbir.jpg")}
                    alt="MÜYORBİR"
                    width={100}
                  />
                </Link>
                <div className="h-100 w-100 ms-3">
                  <h4 className="contact-title">MÜYORBİR Müzik Yorumcuları Meslek Birliği</h4>
                  <p className="m-0">Harbiye Mah. Cumhuriyet Cad. Dörtler Apt. No:42 K:1 Elmadağ-Şişli/İST</p>
                </div>
              </div>

              <div className="d-flex flex-row align-items-center justify-content-start mb-2">
                <Link to="http://tsmb.org/" target="_blank">
                  <img
                    className="rounded img-responsive"
                    src={require("@muzfed/assets/img/logo/meslek-birlikleri/icraci-sanaticlar-ve-muzisyenler.jpg")}
                    alt="TSMB İcracı Sanatçılar ve Müzisyenler Meslek Birliği"
                    width={100}
                  />
                </Link>
                <div className="h-100 w-100 ms-3">
                  <h4 className="contact-title">TSMB İcracı Sanatçılar ve Müzisyenler Meslek Birliği</h4>
                  <p className="m-0">
                    Yıldız Evler Mah. Rabindranath Tagore Cad. Mercan İş Merkezi 28/8 Çankaya / Ankara
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Members;
