import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const GeneralAssemblyDecisions = () => (
  <div className="ac-about-content-area pb-30 pt-30">
    <Container>
      <Row>
        <Col className="wow tpfadeLeft" data-wow-duration=".5s" data-wow-delay=".7s">
          <div className="ac-about-left">
            <ul>
              <li>
                <Link download={true} to="/assets/docs/muzfed-genel-kurul-tutanagi-21.09.2023.pdf" target="_blank">
                  MÜZFED Genel Kurul Toplantı Tutanağı – 21 Eylül 2023'ü indirmek için tıklayınız
                </Link>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default GeneralAssemblyDecisions;
