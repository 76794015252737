import { Col, Container, Row } from "reactstrap";

const Contact = () => (
  <div className="tp-contact-area pb-30">
    <Container>
      <Row>
        <Col>
          <div className="tp-contct-wrapper contact-space-40">
            <div className="tp-contact-thumb">
              <img src="/assets/img/contact/contact-1.jpg" alt="" />
            </div>
            <div className="tp-contact-info mb-40">
              <h4 className="contact-title">E-Posta</h4>
              <span>
                <a href="mailto:(info@muzfed.org)">info@muzfed.org</a>
              </span>
            </div>
            <div className="tp-contact-info mb-40">
              <h4 className="contact-title">Telefon No.</h4>
              <span>
                <a href="tel:(+902122438214)">0 (212) 243 82 14</a>
              </span>
            </div>
            <div className="tp-contact-info">
              <h4 className="contact-title">Adres</h4>
              <span>
                <a href="#!">Kuloğlu Mah. Turnacıbaşı Cad. No:10 K:2 Beyoğlu / İstanbul</a>
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Contact;
