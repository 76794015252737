const faqData = [
  {
    id: "one",
    show: true,
    title: "MÜZFED nedir?",
    desc: "MÜZFED, 5846 sayılı Fikir ve Sanat Eserleri Kanunu’na göre kurulmuş, T.C. Kültür ve Turizm Bakanlığı’na bağlı olarak müzik alanında faaliyet gösteren bağlantılı haklar alanındaki meslek birlikleri olan Mü-yap, Müyorbir, Müyabir, Müzikbir ve TSMB’nin kurduğu müzik sektöründeki fonogram yapımcılarını ve icracıları temsil eden federasyondur.",
  },
  {
    id: "two",
    title: "MÜZFED’in Meslek Birliklerinden farkı nedir?",
    desc: "MÜZFED, Türkiye’deki fonogram yapımcılarını ve icracıları temsil eden meslek birlikleri olan Mü-yap, Müyorbir, Müyabir, Müzikbir ve TSMB’nin otellerin tek elden lisanslanabilmesi ve Müzik Lisans Belgesi’ne daha kolay erişim sağlayabilmesi için kurdukları federasyondur.",
  },
  {
    id: "three",
    title: "Otel dışındaki işletmelerin lisanslanması için ne yapmalıyım?",
    desc: "MÜZFED, otellerin tek elden lisanslanabilmesi için kurulmuş ve üyesi olan meslek birliklerinden bu yönde yetki almış bir federasyondur. Otel dışında bir işletmeniz varsa lisans alabilmek için MÜZFED üyesi meslek birlikleri ile ayrı ayrı görüşmeniz gerekmektedir.",
  },
  {
    id: "four",
    title: "MÜZFED ve MSF’in iş birliği nedir?",
    desc: "Türkiye’deki fonogram yapımcılarını ve icracıları temsil eden meslek birlikleri MÜZFED altında otel lisanslamasın yaparken, eser sahibi meslek birlikleri de MSF ismi altında bir federasyon kurmuştur. MÜZFED ve MSF, otellerin Müzik Lisans Belgesi’ni tek elden kolayca alabilmeleri için bir araya gelmiş ve www.otelllisanslama.org internet sitesini kurmuştur. Bu internet sitesi üzerinden yapacağınız başvuru ile otellerin, Müzik Lisans Belgesi’ni kolayca alabilmesi sağlanmıştır.",
  },
  {
    id: "five",
    title: "Müzik Lisans Belgesi nedir?",
    desc: "Otelinizde müzik yayını yapıyorsanız hak sahiplerinden yazılı izin almanız gerekir. Müzik Lisans Belgesi de MÜZFED federasyonuna üye meslek birliklerinin repertuvarlarının kullanımı için alınması gereken bu izin belgesidir ve işletmenizde yaptığınız müziği yasal hale getirir.",
  },
  {
    id: "six",
    title: "Neden Müzik Lisans Belgesi almalıyım?",
    desc: "Bir işletmede yapılacak müzik yayını / kullanımı için izin almak yasal gerekliliktir. İzinsiz müzik kullanmak hak ihlalidir. Müzik lisansı alarak ayrıca müziği yaratanlara katkıda bulunur ve müziğin geleceği için bu insanları desteklemiş olursunuz. Böylece müzik kültürümüzün geliştirilmesine, müzik icracılarının ve müziği kaydedenlerin sevdiğiniz müziği size ulaştırmalarına devem etmelerini sağlarsınız.",
  },
  {
    id: "seven",
    title: "Müzik Lisans Belgesi aldığımda hangi müzikleri yayınlayabilirim?",
    desc: "Müzik Lisans Belgesi’ne sahip olan bir otel MÜZFED repertuvarında bulunan yerli ve yabancı milyonlarca parçanın yayınını yapabilir. Konseptinize uygun ve işletmenize değer katacak olan müzik tarzını belirlemekte özgür olacaksınız",
  },
  {
    id: "eight",
    title: "Müzik Lisans Belgesi için ne kadar ödemem gerekir?",
    desc: "Müzik Lisans Belgesi bedeli otelinizin oda ve yıldız sayısına göre değişiklik gösterir. Kesin olarak ne kadar ödemeniz gerektiğini öğrenmek için www.otellisanslama.org adresine giderek görebilirsiniz.",
  },
  {
    id: "nine",
    title: "Otelimde sadece TV veya radyo kullanıyorum. Neden lisans alayım?",
    desc: "Otelinizde müzik yayınını hangi vasıta ile yaptığınız fark etmez, yine de yasal izninizi almanız gerekir. İşletmelerde müzik kullanımı / yayını Televizyon veya radyo kullanılmak suretiyle yapılsa bile bunu müşterilerinize ilettiğinizde de Müzik Lisans Belgesi alması gerekir.",
  },
  {
    id: "ten",
    title: "Zaten TV platformunu para verip alıyorum. Neden lisans alayım?",
    desc: "Otelinizde yayınını yaptığınız TV platformlarına ödediğiniz para, platformun yayınları size ulaştırması ve sizin o yayınları kullanabilmeniz içindir. O platformların hiçbiri size müzik yayını izni vermez / veremez. Bu durumu TV platformunuz ile de görüşebilirsiniz. Türkiye’de müzik yayınları ile alakalı izinleri MÜZFED’den alabilirsiniz.",
  },
  {
    id: "eleven",
    title: "Otelimde müzik yayınını internet üzerinden yapıyorum. Yine de izin almalı mıyım?",
    desc: "Otelimde müzik yayınını internet üzerinden youtube gibi platformlardan, Fizy veya Spotify gibi uygulamalardan da yapsanız yine de hak sahiplerini temsil eden MÜZFED’den izin almanız gerekmektedir.",
  },
  {
    id: "twelve",
    title: "Müziği daha önce satın aldıysam, neden bir lisans ücreti ödemeliyim?",
    desc: "CD'den ya da dijital kaynaklardan yani internet üzerinden para ödeyerek satın aldığınız müzikler kişisel kullanımlarınız içindir. Ancak bu müziği kendi dinlemeniz dışında umuma iletirseniz, yani otelinizde müzik yayını yapmakta kullanırsanız Yasalar gereği müzik lisansına yine de ihtiyacınız olacaktır.",
  },
  {
    id: "thirteen",
    title: "Param nereye gidiyor?",
    desc: "Aldığınız müzik lisansı için ödediğiniz lisans bedeli MÜZFED tarafından kendi üyelerine dağıtılmaktadır. Federasyonumuz Bakanlığın idari ve mali denetimine tabidirler. Ödediğiniz lisans bedellerinin üyelerimize dağıtılması ile ilgili olarak da denetlenmektedir.",
  },
  {
    id: "fourteen",
    title: "Müzik Lisans Belgesi almadan müzik yayınına devam edersem ne olur?",
    desc: "Otelinizde yaptığınız müzik kullanımları ve yayınları için Müzik Lisans Belgesi almak kanunen zorunludur. Müzik Lisans Belgesi alınmadan müzik yayınlarına devam etmeniz kanuna aykırı hareket etmenize sebep olur. Bu durumda MÜZFED bütün yasal haklarını kullanarak işletmeniz için hukuki işlem başlatabilir. ",
  },
  {
    id: "fifteen",
    title: "MÜZFED hakkında hangi merciden bilgi alabilirim?",
    desc: "MÜZFED hakkındaki tüm bilgileri T.C. Kültür ve Turizm Bakanlığı Telif Hakları Genel Müdürlüğü’nden (www.telifhaklari.gov.tr, 0.312.4706814) alabilirsiniz.",
  },
];

export default faqData;
