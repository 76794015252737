import useSticky from "@muzfed/hooks/useSticky";
import { Link } from "react-router-dom";
import NavMenus from "./NavMenus";
import { Col, Container, Row } from "reactstrap";
import MobileMenu from "./MobileMenu";

const SubPageHeader = () => {
  const { headerSticky } = useSticky();

  return (
    <>
      <header className="d-none d-lg-block">
        <div
          id="header-sticky"
          className={`tp-header-area-two tp-header-bg header-transparent header-transparent-two ${
            headerSticky ? "header-sticky" : ""
          }`}
        >
          <Container fluid>
            <Row className="align-items-center">
              <Col xxl={2} xl={2} lg={2}>
                <div className="tp-logo text-start">
                  <Link to="/">
                    <img src={require("@muzfed/assets/img/logo/muzfed-logo.png")} alt="Logo" />
                  </Link>
                </div>
              </Col>
              <Col xxl={8} xl={8} lg={8}>
                <div className="tp-main-menu tp-menu-black tp-bs-menu tp-bp-menu text-center">
                  <nav id="mobile-menu">
                    {/* nav menus start */}
                    <NavMenus />
                    {/* nav menus end */}
                  </nav>
                </div>
              </Col>
              <Col xxl={2} xl={2} lg={2}>
                <div className="tp-header-left d-flex align-items-center justify-content-end ">
                  <div className="tp-header-yellow-button">
                    <Link to="https://otellisanslama.org" target="_blank" className="tp-btn-white">
                      Başvuru Yap
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </header>

      <MobileMenu transparent={false} />
    </>
  );
};

export default SubPageHeader;
