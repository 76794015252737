import { LayoutProps } from "@muzfed/types";
import React, { useEffect, useLayoutEffect } from "react";
import Header from "./components/Header";
import { animationCreate } from "@muzfed/utils/animation";
import Footer from "./components/Footer";
import { useLocation } from "react-router-dom";

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      animationCreate();
    }, 50);
  }, []);

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
