import { Company } from "@muzfed/types";
import { ReactNode } from "react";

const CompanyCard: React.FC<Company> = ({ addressName, roomCount, calculationType, userCompanyName }) => {
  const renderCalculationType = () => {
    let calculationTypeContent = null;
    let starContent: ReactNode[] = [];
    for (let i = 0; i < calculationType; i++) {
      starContent.push(<i key={`star-${i}`} style={{ color: "#c03c43" }} className="fas fa-star me-1"></i>);
    }

    if (calculationType === 10)
      calculationTypeContent = (
        <span className="fw-bold" style={{ color: "#c03c43" }}>
          Basit Konaklama
        </span>
      );
    else if (calculationType >= 1 && calculationType <= 5)
      calculationTypeContent = <div className="tp-testimonial-item-four__rating">{starContent}</div>;
    else if (calculationType === 6)
      calculationTypeContent = (
        <span className="fw-bold" style={{ color: "#c03c43" }}>
          1. Sınıf Tatil Köyü
        </span>
      );
    else if (calculationType === 7)
      calculationTypeContent = (
        <span className="fw-bold" style={{ color: "#c03c43" }}>
          2. Sınıf Tatil Köyü
        </span>
      );
    else if (calculationType === 8) calculationTypeContent = <span style={{ color: "#c03c43" }}>Butik Otel</span>;
    else if (calculationType === 9)
      calculationTypeContent = (
        <span className="fw-bold" style={{ color: "#c03c43" }}>
          Özel Konaklama Tesisi{" "}
        </span>
      );

    return calculationTypeContent;
  };

  return (
    <div className="tp-testimonial-item-four" style={{ height: "300px" }}>
      <div
        className="tp-testimonial-item-four__meta d-flex justify-content-between 
      align-items-center mb-60"
      >
        {renderCalculationType()}

        <div className="tp-testimonial-item-four__logo">
          <i className="fas fa-bed me-1" style={{ color: "#c03c43" }}></i> {roomCount}
        </div>
      </div>
      <div className="tp-testi-paragraph pb-10">
        <p></p>
      </div>
      <div className="tp-testimonial-item-four__client d-flex align-items-center">
        <div className="tp-testimonial-item-four__position">
          <h4 className="m-0">{userCompanyName.toLocaleUpperCase("tr-TR")}</h4>
          <span>{addressName.toLocaleUpperCase("tr-TR")}</span>
        </div>
      </div>
    </div>
  );
};

export default CompanyCard;
