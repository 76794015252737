import CompanyCard from "@muzfed/components/CompanyCard";
import Pagination from "@muzfed/components/Pagination";
import config from "@muzfed/config";
import { CompanyResponse } from "@muzfed/types";
import axios, { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const Companies = () => {
  const pageSize = 12;

  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<CompanyResponse>({ recordCount: 0, records: [] });

  const page = parseInt(searchParams.get("page") || "1");

  const fetchApprovedApplications = useCallback(async (page: number, pageSize: number) => {
    try {
      setLoading(true);
      let url = `${config.API_URL}/application/approved?pageSize=${pageSize}&page=${page}`;
      const response = await axios.get<CompanyResponse>(url);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          setError(String(axiosError.response.data));
        } else {
          setError(axiosError.message);
        }
      } else {
        const er = error as Error;
        setError(er.message);
      }
    }
  }, []);

  useEffect(() => {
    fetchApprovedApplications(currentPage, pageSize);
  }, [currentPage, fetchApprovedApplications]);

  useEffect(() => {
    if (page) {
      setCurrentPage(page);
    }
  }, [page]);

  useEffect(() => {
    setTotalPages(Math.ceil(data.recordCount / pageSize));
  }, [data.recordCount]);

  return (
    <div className="tp-testimonial-area pt-30 pb-30 p-relative">
      <Container>
        <Row>
          {data.records.map((item, index) => (
            <Col key={index} md={4} className="mb-3">
              <CompanyCard key={index} {...item} />
            </Col>
          ))}

          <Col lg={12}>
            <Pagination currentPage={currentPage} totalPages={totalPages} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Companies;
