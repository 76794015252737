import { Col, Container, Row } from "reactstrap";

const Charter = () => (
  <Container className="pt-30 pb-30">
    <Row>
      <Col className="wow tpfadeLeft" data-wow-duration=".5s" data-wow-delay=".7s">
        <h4 className="text-black text-center">MÜZFED – MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU TÜZÜĞÜ</h4>
        <h6 className="mt-100 fw-bold">BİRİNCİ BÖLÜM</h6>
        <h6 className="fw-bold">GENEL HÜKÜMLER</h6>

        <h6 className="fw-bold">Federasyon Adı, Merkezi ve Faaliyet Alanı</h6>
        <p>
          <b>Madde 1 -</b> MÜZFED-MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU, 5846 sayılı Fikir ve Sanat Eserleri
          Kanunu’nun 42’nci maddesi ve Telif Hakları Alanında Meslek Birlikleri Yönetmeliği’nin 60’ıncı maddesi
          hükümlerine göre kurulmuş olup işbu Tüzüğün kabulü ile Telif Hakları Alanında Meslek Birlikleri Yönetmeliği’ne
          (Yönetmelik) uygun olarak faaliyet gösterecektir.
        </p>
        <p>
          MÜZFED-MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU merkezi ‘Kuloğlu Mah. Turnacıbaşı Sk. Saray Han No:10
          Kat:1 Beyoğlu İstanbul’dadır. MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’nun kısa adı “MÜZFED”dir. Bu
          Federasyon, “Telif Hakları Alanında Meslek Birlikleri Yönetmeliği’nin 60’ıncı maddesi uyarınca müzik
          sektöründe fonogram yapımcılarının ve müzik icracılarının 5846 sayılı FSEK kapsamındaki mali haklarının
          08.06.2004 tarihli ve 25486 sayılı Resmi Gazetede yayımlanmış bulunan ‘Eser, İcra, Yapım ve Yayınların
          Kullanılması ve/veya İletilmesine İlişkin usul ve Esaslar Hakkında Yönetmelik’in Ek-A’sında belirtilen ve
          Türkiye Cumhuriyeti sınırları içerisinde bulunan konaklama tesislerinde kullanılmasının takibi, idaresi ve
          tahsili amacıyla müzik sektörü alanında faaliyet gösterir.
        </p>

        <h6 className="fw-bold">Federansyonun Amacı</h6>
        <p>
          <b>Madde 2 -</b> MÜZFED-MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’nun (MÜZFED) amaçlarını;
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              MÜZFED-MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’nu oluşturan
              <ol>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Kuloğlu Mah. Turnacıbaşı Cad. Saray Han No:10 K:5 34433 Beyoğlu-İstanbul adresinde faaliyet gösteren
                  <b>MÜ-YAP Bağlantılı Hak Sahibi Fonogram Yapımcıları Meslek Birliği,</b>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Merkez Efendi Mahallesi-Mevlâna Caddesi Tercüman Sitesi A-10 Blok Kat:8 Daire:36, 34015 adresinde
                  faaliyet gösteren <b>MÜYABİR Bağlantılı Hak Sahibi Fonogram Yapımcıları Meslek Birliği,</b>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Gülbahar Mahallesi Büyükdere Cad. Gayret Apt. No:105 K:1, D:1, 34083 Şişli/İstanbul adresinde faaliyet
                  gösteren <b>MÜZİKBİR Bağlantılı Hak Sahibi Fonogram Yapımcıları Meslek Birliği,</b>
                  <p>
                    unvanlı meslek birlikleri tarafından verilen yetki belgeleri kapsamında; 5846 sayılı Fikir ve Sanat
                    Eserleri Kanunu’nun değişik 80. maddesinin kapsamında bir icra ürünü olan veya sair sesleri ilk defa
                    tespit eden yukarıda adları belirtilen meslek birliklerine üye olan fonogram yapımcılarının
                    fonogramlarının, ses kayıtlarının ve/veya ses tespitlerinin Türkiye Cumhuriyeti sınırları içerisinde
                    bulunan konaklama tesislerinde kullanılmasının takibi, idaresi ve fonogramların, ses tespitlerinin
                    ve ses kayıtlarının kullanılmasından kaynaklanan ücretler ile alınacak tazminatların tahsilini, hak
                    sahiplerine dağıtımını sağlanması,
                  </p>
                </li>
              </ol>
            </li>
            <li style={{ listStyleType: "decimal" }}>
              MÜZFED-MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’nu oluşturan
              <ol>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Harbiye Mah. Cumhuriyet Cad. Dörtler Apt. No:42 K:1 Elmadağ-Şişli/İST adresinde faaliyet gösteren{" "}
                  <b>MÜYORBİR Müzik Yorumcuları Meslek Birliği,</b>
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Yıldız Evler Mah. Rabindranath Tagore Cad. Mercan İş Merkezi 28/8 Çankaya / Ankara adresinde faaliyet
                  gösteren <b>TSMB İcracı Sanatçılar ve Müzisyenler Meslek Birliği,</b>
                  <p>
                    Unvanlı meslek Birlikleri tarafından verilen yetki belgeleri kapsamında; 5846 sayılı Fikir ve Sanat
                    Eserleri Kanunu’nun değişik 80. maddesi uyarınca bir müzik icrasının Türkiye Cumhuriyeti sınırları
                    içerisinde bulunan konaklama tesislerinde kullanılmasına ilişkin mali haklarının takibi, idaresi ve
                    bu mali hakların kullanılmasından kaynaklanan ücretler ile alınacak tazminatların tahsilini, hak
                    sahiplerine dağıtımını sağlanması, Teşkil etmektedir.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Tüzel Kişilik</h6>
        <p>
          <b>Madde 3 -</b> MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’nu, 5846 sayılı Kanun’un 4110 sayılı
          Kanunla değişik 42’nci maddesi uyarınca Cumhurbaşkanlığı tarafından 07 Nisan 2022 tarihli ve 31802 sayılı
          Resmî Gazetede yayımlanarak yürürlüğe giren Telif Hakları Alanında Meslek Birlikleri Yönetmeliği’ne uygun
          biçimde düzenlenmiş MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU Tüzüğünün eklendiği kuruluş
          bildirisinin, Bakanlığa verilmesine müteakip tüzel kişilik kazanır.
        </p>
        <h6 className="fw-bold">Federasyonun Faaliyetleri</h6>
        <p>
          <b>Madde 4 -</b> MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU amacına ulaşmak için, mevzuatın
          verdiği imkanlar içinde faaliyetleri;
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              08.06.2004 tarihli ve 25486 sayılı Resmi Gazetede yayımlanmış bulunan ‘Eser, İcra, Yapım ve Yayınların
              Kullanılması ve/veya İletilmesine İlişkin usul ve Esaslar Hakkında Yönetmelik’in Ek-A’sında belirtilen ve
              Türkiye Cumhuriyeti sınırları içerisinde bulunan konaklama tesislerinin lisanslanması ile ilgili ve
              sınırlı olarak üyelerini oluşturan ve yukarıda 2. maddede adları belirtilen meslek birliklerinin koruması
              ve takibi altında bulunan fonogramlar ve icralarının Türkiye Cumhuriyeti sınırları içerisinde bulunan
              konaklama tesislerinde kullanılmasına ilişkin mali haklarının takibi, idaresi ve bu mali hakların
              kullanılması için yazılı izinlerin vermek, lisans sözleşmelerini imzalamak, tüm lisanslama işlemlerini
              yapmak, bu mali hakların kullanılmasından kaynaklanan ücretler ile alınacak tazminatların tahsilini, hak
              sahiplerine dağıtımını sağlamak, idari ve yargı yollarına başvurmak, hukuki ve cezai başvuruların
              yapılması ile ilgili olarak çalışmaları yapmak,
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Kullanıcıların ve diğer ilgili kişilerin, 5846 sayılı Fikir ve Sanat Eserleri Kanunu ve diğer ilgili
              mevzuat çerçevesinde bilinçlendirilmeleri için tanıtım, reklam, seminer, yayın gibi farkındalık
              çalışmalarını yapmak,
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Bu faaliyetler kapsamında, idari kuruluşlara başvurular yapmak, yurt içinde kamu kurum ve kuruluşları,
              gerçek ve özel hukuk tüzel kişileri ile idari ve mesleki ilişkiler kurmak, mesleki yayınlar yapmak, diğer
              ilgili alan meslek birlikleri ve federasyonlar ile iş birlikleri yapmaktır.
            </li>
          </ol>
        </p>
        <h6 className="fw-bold">Üye Meslek Birliklerinin Devam Eden Konaklama Tesisleri Sözleşmeleri</h6>
        <p>
          <b>Madde 5 -</b> Federasyonun üyesi olan meslek birliklerinin Federasyonun kurulması tarihinde devam eden
          konaklama tesisleri sözleşmeleri gereğince sahip oldukları hak ve yükümlülükler de Federasyona yetki belgesi
          imzalanması suretiyle devredilir.
        </p>
        <h6 className="fw-bold">Federasyonun Yükümlülükleri ve Tarife Tespitine İlişkin Esaslar</h6>
        <p>
          <b>Madde 6:</b>
          <br />
          Federasyon
          <ol>
            <li style={{ listStyleType: "decimal" }}>4’üncü maddede belirtilen faaliyetleri yerine getirmek,</li>
            <li style={{ listStyleType: "decimal" }}>
              Üyesi olan meslek birliklerinin yetki belgesi ile 08.06.2004 tarihli ve 25486 sayılı Resmî Gazetede
              yayımlanmış bulunan ‘Eser, İcra, Yapım ve Yayınların Kullanılması ve/veya İletilmesine İlişkin usul ve
              Esaslar Hakkında Yönetmelik’in Ek-A’sında belirtilen ve Türkiye Cumhuriyeti sınırları içerisinde bulunan
              konaklama tesisleri bakımından kendisine takip yetkisini devrettikleri mali hakların idaresini hakkaniyete
              uygun koşullarda sağlamakla,
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Temsil ettiği icra ve fonogramlara ilişkin üye meslek birliklerinin kendisine yaptığı bildirimler
              çerçevesinde ortak bir veri tabanı oluşturmakla,
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Yazılı talepte bulunan ilgili kişilere, temsil ettiği icra ve fonogramlar ile ilgili bilgileri vermekle,
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Tahsil edeceği izin ve tazminat bedellerini, Federasyon Dağıtım Yönergesi’ne uygun olarak dağıtılmasını
              sağlamakla,
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Sözleşme yaparken idare ettiği mali haklara ilişkin olarak hakkaniyete uygun davranmak, üye meslek
              birliklerinin maddî ve/veya manevî menfaatleri bakımından gerekli gördükleri indirim veya ödeme
              kolaylıklarını sağlamakla,
            </li>
            <li style={{ listStyleType: "decimal" }}>
              İzinsiz olarak müzik kullanımı yapan konaklama tesisleri ile ilgili olarak idari ve hukuki başvuruları
              yapmakla,
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Sözleşme yapılabilmesi için idaresini sağladığı mali haklara ilişkin ücret tarifelerini süresinde
              belirlemek ve belirlenen tarifeleri ve bu tarifelerdeki her türlü değişikliği süresinde duyurmakla,
            </li>
            <li style={{ listStyleType: "decimal" }}>Hesaplarını yeminli malî müşavirlere onaylatmakla,</li>
          </ol>
          Yükümlüdür.
        </p>

        <h6 className="mt-100 fw-bold text-center">İKİNCİ BÖLÜM</h6>
        <h6 className="fw-bold text-center">Birlik Üyeliği</h6>

        <h6 className="fw-bold">Üye Olma Hakkı ve Yükümlülükleri</h6>
        <p>
          <b>Madde 7 -</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Telif Hakları Alanında Meslek Birlikleri Yönetmeliği’nin yürürlüğe girmesinden sonra veya bu Yönetmeliğin
              yürürlüğe girmesinden önce mevcut yasal mevzuata göre fonogram yapımcılığı ve icracı sanatçılar alanında
              kurulmuş meslek birlikleri MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’na üye olabilir.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Hiç kimse MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’na üye olmaya veya MÜZFED- MÜZİK
              SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’nda üye olarak kalmaya zorlanamaz.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Üye meslek birlikleri, genel kurulca belirtilen giriş ödentisini ve yıllık ödentiyi ödemekle
              yükümlüdürler. Üyelik başvurusunun, Federasyon Yönetim Kurulu kararı ile kabul edildiğinin başvurana
              bildirilmesi ile başvuran tarafından MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU Genel
              Kurulu’nca belirlenen koşullara uygun olarak düzenlenmiş yetki belgesinin verilmesi ve giriş ödentisinin
              ödenmesi zorunludur.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Meslek Birliklerinin üye olabilmeleri Türk Yasalarına göre kurulmuş olmaları koşuluna bağlıdır. Üye Meslek
              Birlikleri MÜZFED-MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU nezdinde kendi genel kurulu kararıyla
              görevlendirdikleri temsilciler tarafından temsil edilir. Bu kişinin temsil görevi sona erdiğinde, üye
              meslek birliğini temsil edecek kişi, ilgili meslek birliği yönetim kurulu tarafından bildirilir.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Üyelik Şartları</h6>
        <p>
          <b>Madde 8 -</b>
          <br />
          MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’na üye olabilmek için aşağıdaki nitelikleri taşımak
          gerekir:
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              5846 sayılı Fikir ve Sanat Eserleri Kanunu kapsamında Türkiye’de fonogram yapımcılığı ve müzik icracıları
              alanlarında faaliyet gösteren meslek birliği olmak,
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Meslek birliği olarak medeni hakları kullanma ehliyetine sahip olmak,
            </li>
            <li style={{ listStyleType: "decimal" }}>
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’nca belirlenen koşullara uygun olarak belirlenmiş
              yetki belgesini vermek ve giriş ödentisini ödemek,
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Üyelik Başvurusu ve Kabul</h6>
        <p>
          <b>Madde 9:</b>

          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Üye olmak isteyen müzik sektöründe faaliyet gösteren meslek birlikleri, MÜZFED- MÜZİK SEKTÖRÜNDE
              BAĞLANTILI HAKLAR FEDERASYONU’na yazılı olarak başvururlar. Yapılan başvurular, işbu Tüzük’te belirtilen
              şartlara göre Federasyon Yönetim Kurulunca karara bağlanır ve başvuru tarihinden itibaren altmış gün
              içinde ilgiliye yazı ile bildirilir. Ret kararlarında gerekçe belirtilir.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Kanunda, Yönetmelikte ve bu Tüzükte öngörülen nitelik ve ölçütleri taşıyan, yetki belgesini veren ve giriş
              ödentisini ödeyen müzik sektöründe faaliyet gösteren meslek birlikleri üyeliğe kabul edilirler. Üyeliğe
              kabul kararı, üyelik başvurusu yapan meslek birliğine bildirilir.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Üyeliğin Sona Ermesi ve İlişkilerin Tasfiyesi</h6>
        <p>
          <b>Madde 10:</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU üyeliği;
              <ol>
                <li style={{ listStyleType: "lower-alpha" }}>Tüzel kişiliğin sona ermesi,</li>
                <li style={{ listStyleType: "lower-alpha" }}>Üyelikten ayrılma,</li>
                <li style={{ listStyleType: "lower-alpha" }}>Üyelikten çıkarılma,</li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Üyelik için aranılan niteliklerin sonradan kaybedilmesi,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>Yetki belgesinin konusuz kalması</li>
              </ol>
              İle sona erer. Bu durumda, üyelik Federasyon Yönetim Kurulu kararıyla açıklaması da yapılarak üye
              defterinden silinir.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon üyeliği sona erenler ile MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’nun karşılıklı
              yükümlülükleri, yetki belgesinin geçerliliği kapsamında belirlenir. Federasyon Yönetim Kurulu, yetki
              belgesinin geçerlilik süresinin derhal sona erdiğine ya da en geç takvim yılı sonunda yürürlüğe gireceğine
              karar verebilir.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Üyelikten Ayrılma</h6>
        <p>
          <b>Madde 11:</b>
          <br />
          Üyeler, gerekçe göstermeksizin birlik üyeliğinden ayrılma isteğinde bulunabilir. Üyelik, bu başvuruyla sona
          erer. Federasyon Yönetim Kurulu, yetki belgesinin geçerlilik süresinin istifa tarihinde ya da en geç takvim
          yılı sonunda yürürlüğe gireceğine karar verebilir.
        </p>

        <h6 className="fw-bold">Üyelikten Çıkarma</h6>
        <p>
          <b>Madde 12:</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Üyeler, aşağıdaki hallerde Federasyon Yönetim Kurulunun önerisi ve Federasyon Haysiyet Kurulunun kararıyla
              üyelikten çıkartılırlar:
              <ol>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Yazılı uyarıya rağmen bir yıl süreyle yıllık üyelik ödentisini vermemek,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Yazılı uyarıya rağmen süresi dolan yetki belgesini yenilememek.
                </li>
              </ol>
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Üyeler, Federasyon Haysiyet Kurulu kararıyla üyelikten çıkarılırlar. Çıkarılma kararı noter aracılığıyla
              yedi gün içinde ilgiliye bildirilir.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Meslek Birlikleri, üyelikten çıkarılma kararlarına karşı bir ay içerisinde Haysiyet Kuruluna yazılı olarak
              itiraz edilebilir.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Üyelerin Temsili ve Hakların Takibi</h6>
        <p>
          <b>Madde 13-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU, kendisine yetki belgesi veren ve üyelik
              başvurusunu kabul ettiği meslek birliklerini Türkiye Cumhuriyeti sınırları içiresinde faaliyet gösteren
              konaklama tesislerinin lisanslanması ve izin bedellerinin ve tazminatların tahsil edilerek üyelerine
              dağıtılması, gerekli olması halinde idari ve yargı yollarına başvuru yapılması hususları ile ilgili ve
              sınırlı olarak temsil eder.
              <br />
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU, kamu kurum ve kuruluşları, gerçek kişiler ve özel
              hukuk tüzel kişileriyle ilişkilerde üyesi olan meslek birliklerinin temsil ettiği fonogram yapımcıları ve
              icracı sanatçıların haklarını; üye meslek birlikleri tarafından verilen yetki belgesi ve mevzuat
              kapsamında yönetir. Üye meslek birlikleri tarafından Federasyona verilen yetki belgesinde belirtilen hak
              ve yetkiler bölünemez.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU, üye meslek birliklerinin federasyona verdikleri
              yetki belgesine konu hakları münhasıran takip etmeye yetkilidir.
            </li>
          </ol>
        </p>

        <h6 className="mt-100 fw-bold text-center">ÜÇÜNCÜ BÖLÜM</h6>
        <h6 className="fw-bold text-center">Federasyon Organları ve Şubeler</h6>

        <h6 className="fw-bold">Federasyon Organları</h6>
        <p>
          <b>Madde 14:</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’nun zorunlu organları şunlardır:
              <ol>
                <li style={{ listStyleType: "lower-alpha" }}>Genel kurul,</li>
                <li style={{ listStyleType: "lower-alpha" }}>Yönetim kurulu,</li>
                <li style={{ listStyleType: "lower-alpha" }}>Denetleme kurulu,</li>
                <li style={{ listStyleType: "lower-alpha" }}>Teknik-bilim kurulu,</li>
                <li style={{ listStyleType: "lower-alpha" }}>Haysiyet kurulu,</li>
              </ol>
            </li>
            <li style={{ listStyleType: "decimal" }}>
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU görevlerinin ve üyelerinin seçim usulünü MÜZFED-
              MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU Tüzüğünde göstermek kaydıyla zorunlu organları dışında
              ihtiyari organlar oluşturabilir. Ancak zorunlu organların görev, yetki ve sorumlulukları ihtiyari
              organlara devredilemez.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’nun ihtiyari organı aşağıda belirtilmiştir:
              <br />- Mali Denetim Kurulu
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Genel Kurul</h6>
        <p>
          <b>Madde 15-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Genel kurul, federasyonun en yetkili karar organıdır. Genel kurul üyesi olan meslek birliklerinin
              Federasyona bildirdiği delegelerden oluşur. MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU,
              faaliyet izni aldıktan sonra ilk genel kurulunu toplar.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’nun genel kurulları delege sayısı her zaman,
              yapımcı meslek birlikleri toplamı (Müyap + Müyabir + Müzikbir) 25 delege, icracı meslek birlikleri toplamı
              (Müyorbir + Tsmb) 20 delege olacak şekilde toplam 45 delegeden oluşur.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              48’inci maddede belirtilen ortak veri tabanının oluşturulmasını ve 35’inci maddede belirtilen dağıtımda
              kullanım oranları esas alınarak yapılmasını takip eden 24 ay sonrasından itibaren yapılacak ilk olağan
              genel kurul toplantısından başlamak üzere meslek birliklerinin genel kurullardaki delege sayıları
              aşağıdaki esaslara göre belirlenecektir:
              <ol>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyonun genel kurul toplantılarında her bir meslek birliği en az beş adet delegeye her zaman
                  sahip olacaktır.
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Buna göre yapımcı meslek birliklerinin ve icracı meslek birliklerinin en az delege sayısı dışında
                  kalacak onar adet delegeleri, 48’inci madde gereğince ortak veri tabanının kurulmasının ardından
                  kullanım oranlarına göre meslek birliklerinin temsil ettikleri repertuvarın kullanımlarının
                  birbirlerine oranlamasına göre meslek birlikleri arasında (Müyap, Müyabir ve Müzikbir kendi
                  aralarında, Müyorbir ve Tsmb kendi aralarında) paylaşılacaktır. En az delege dışındaki delegelerin
                  paylaşımı, meslek birliklerinin en az sayıdaki delegelerinin karşılık geldiği yüzde oranını aşması
                  halinde yapılacaktır.
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Bu esaslara göre delege sayılarının son olağan genel kurul toplantısından itibaren kullanım oranlarına
                  göre dağılımı ile ilgili bilgilendirmeyi Yönetim Kurulu her genel kurul toplantısından iki ay öncesine
                  kadar üye meslek birliklerine yapar. Meslek birlikleri bu raporlama çerçevesinde delege isim
                  listelerini federasyon genel kurul tarihinden en az bir ay önce federasyona bildirmek zorundadır.
                  Yönetim kurulu genel kurul hazirun cetvelini buna göre hazırlar.
                </li>
              </ol>
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon’un ilk genel kurulu ile yukarıda 3’üncü fıkra gereğince delege sayılarının değişeceği olağan
              genel kurul toplantısına kadarki tüm genel kurul toplantıları; Mü-Yap tarafından bildirilecek 15 delege.
              Müyorbir tarafından bildirilecek 15 delege, TSMB tarafından bildirilecek 5 delege, Müyabir tarafından
              bildirilecek 5 delege ve Müzikbir tarafından bildirilecek 5 delege ile yapılacaktır.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Federasyon Genel Kurulunun görevleri ve yetkileri</h6>
        <p>
          <b>Madde 16:</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon Genel kurulun görevleri ve yetkileri şunlardır.
              <ol>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Bir sonraki Federasyon olağan genel kuruluna kadar görev yapmak üzere Federasyon Yönetim Kurulu,
                  Federasyon Denetleme Kurulu, Federasyon Teknik-Bilim Kurulu ve Federasyon Haysiyet Kurulunun bu
                  Tüzükte belirlenen asıl ve yedek üyelerini Federasyon genel kurulu tarafından belirlenecek usul ile
                  seçmek,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>Bütçe tasarısını görüşmek ve karara bağlamak,</li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Kurullarca öngörülen raporları incelemek ve karara bağlamak,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon Yönetim Kurulunun hesaplarını incelemek ve ibra etmek,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon Tüzüğündeki değişiklik önerilerini karara bağlamak,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyonun konaklama tesislerinden elde edilecek telif gelirler ve tazminatlar ile bu gelirlerle
                  yapılan yatırımlardan elde edilen gelirden kesilecek Federasyon payı ile kültürel ve sosyal amaçlarla
                  yapılacak kesintileri makul bir oranda belirlemek,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon Dağıtım Yönergesi ve diğer yönerge önerilerini karara bağlamak,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon giriş aidatı ve üyelik aidatı tutarlarını belirlemek,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon zorunlu organlarının başkan ve üyelerine verilecek ücretleri belirlemek,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon Yönetim Kurulunun genel kurulda incelenmesini önerdiği hususları görüşmek ve karara
                  bağlamak,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyonun şube açmasına veya kapatmasına karar vermek,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyonun amacına ve faaliyet alanına ilişkin şirket kurmasına ve kurulmuş olan şirketlere ortak
                  olmasına dair kararı onaylamak,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Taşınmaz mal alınması ve satılması ile bunlar üzerinde ayni haklar tesis edilmesini onaylamak,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>Risk yönetim politikasına karar vermek,</li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyonun borç almasını, borç vermesini ya da alınan borçlar için teminat vermesini onaylamak,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Mevzuat ve Federasyon Tüzüğü ile verilmiş olan diğer görevleri yapmak.
                </li>
              </ol>
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Genel Kurulun Toplanması</h6>
        <p>
          <b>Madde 17-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon genel kurulu, iki yılda bir kez en geç aralık ayı sonuna kadar Yönetim Kurulu’nun çağrısı
              üzerine toplanır. Federasyon Olağan genel kurul toplantılarının en geç iki yılda bir yapılması zorunludur.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon Genel kurulu; Federasyon Yönetim Kurulu veya Federasyon Denetleme Kurulunun gerekli gördüğü
              hallerde veya federasyon genel kurulu toplam delege sayısının beşte birinin yazılı başvurusu üzerine,
              Federasyon Yönetim Kurulunca olağanüstü toplantıya çağrılır.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Denetleme Kurulu veya federasyon genel kurulu toplam delege sayısının beşte birinin yazılı isteğine rağmen
              Yönetim Kurulu istek yazısının kendisine ulaştığı tarihten itibaren otuz gün içinde federasyon genel
              kurulunu olağanüstü toplantıya çağırmazsa, Federasyon Denetleme Kurulunun veya toplantı isteğinde bulunan
              delegelerin başvurusu üzerine Bakanlık, genel kurul toplanması için yeter sayı olarak kabul edilen
              delegeyi, federasyon genel kurulunu olağanüstü toplantıya çağırmakla görevlendirir.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Genel Kurulun Toplantıya Çağrı Usulü</h6>
        <p>
          <b>Madde 18-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon Yönetim Kurulu, MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU Tüzüğüne göre genel
              kurula katılma hakkı bulunan delegelerin listesini düzenler. Federasyon Genel kuruluna katılma hakkı
              bulunan delegeler; en az on beş gün önceden, toplantının günü, saati, yeri, gündemi ve toplantıya katılma
              hakkı bulunan delegeleri içeren bir liste ile delegelerin bildirdiği adrese iadeli taahhütlü posta yoluyla
              yazılı olarak, imza karşılığında elden verilmek veya elektronik posta adresine ya da iletişim adresine
              mesaj gönderilmek suretiyle toplantıya çağrılır. Bu çağrıda, çoğunluk sağlanamaması sebebiyle toplantı
              yapılamazsa, ikinci toplantının hangi gün, saat ve yerde yapılacağı da belirtilir. İlk toplantı ile ikinci
              toplantı arasındaki süre yedi günden az, altmış günden fazla olamaz.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Toplantı yeri, günü, saati ve gündemi, toplantı gününden en az on beş gün önce Bakanlığa yazı ile
              bildirilir. Bu yazıya, toplantıya katılacak üyelerin listesi de eklenir.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Federasyon Genel Kurul Toplanma ve Karar Yeter Sayısı</h6>
        <p>
          <b>Madde 19-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Genel kurul, Federasyon delegelerinin tam sayısının yarısından bir fazlasıyla toplanır. İlk toplantıda
              yeter sayı sağlanamazsa ikinci toplantıda çoğunluk aranmaz.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon Genel kurul delegeleri bir başka delegeye verdikleri noterden onaylı vekâletname ile oy
              kullanabilirler. Her delege ancak bir delegenin vekâletini alabilir. Federasyon Yönetim Kurulu ve
              Federasyon Denetleme Kurulu üyeleri genel kurulda vekâletname ile temsil edilemez. Federasyon Yönetim
              Kurulu ve Federasyon Denetleme Kurulu üyeleri de başka bir üyeyi vekâleten temsil edemez.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon Genel kurul kararları, toplantıda hazır bulunan oyların çoğunluğu ile alınır. 35’inci maddesi
              için aşağıda 4. Fıkrada belirtilen usul hariç olmak üzere Tüzük değişikliği ve federasyonun feshi
              kararları, ancak hazır bulunan oyların üçte iki çoğunluğuyla alınır.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              <u>Tüzüğün 35’inci maddesi değişikliği usulü:</u> Tüzüğün 35’inci maddesinin ve işbu 19’uncu maddenin 4.
              Fıkrasının değiştirilmesi kararları ancak genel kurulda hazır bulunan oyların üçte iki çoğunluğu ve aynı
              zamanda 35’inci maddede belirtilen payı azalacak meslek birliğinin veya meslek birliklerinin en az üç
              delegesinin olumlu yönde oy kullanması ile alınır.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Federasyon Genel Kurul Toplantısı Usulü</h6>
        <p>
          <b>Madde 20-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon delegelerinin listesi toplantı yerinde hazır bulundurulur. Toplantı yerine girecek üyelerin
              resmi makamlarca verilmiş kimlik belgeleri, Federasyon Yönetim Kurulu üyeleri veya Federasyon Yönetim
              Kurulunca görevlendirilecek görevliler tarafından kontrol edilir. Delegeler, Federasyon Yönetim Kurulunca
              düzenlenen listedeki adları karşısına imza atarak toplantı yerine girerler. Kimlik belgesini
              göstermeyenler, belirtilen listeyi imzalamayanlar ile verilecek karara göre genel kurula katılma hakkı
              bulunmayan delegeler toplantı yerine alınmaz.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Toplantı yeter sayısı sağlanmışsa durum bir tutanakla tespit edilir ve toplantı, Federasyon Yönetim Kurulu
              başkanı veya görevlendireceği Federasyon Yönetim Kurulu üyelerinden biri tarafından açılır. Toplantı yeter
              sayısı sağlanamaması halinde Yönetim Kurulunca bir tutanak düzenlenir.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Açılıştan sonra, toplantıyı yönetmek üzere bir başkan ve yeteri kadar başkan vekili ile yazman seçilerek
              divan heyeti oluşturulur. Federasyon organlarının seçimi için yapılacak oylamalarda, oy kullanan üyelerin
              divan heyetine veya yetkilendirdiği kişilere kimliklerini göstermeleri ve hazırun listesindeki isimlerinin
              karşısını imzalamaları zorunludur. Toplantının yönetimi ve güvenliğinin sağlanması divan başkanına aittir.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon Genel kurul toplantısında yalnız gündemde yer alan maddeler görüşülür. Ancak toplantıda hazır
              bulunan delegelerin en az beşte biri tarafından görüşülmesi yazılı olarak istenen konuların gündeme
              alınması zorunludur. Federasyon genel kurulu, gündemdeki konuların görüşülerek karara bağlanmasıyla
              sonuçlandırılır.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon tahmini bütçesinin federasyon genel kurulda kabul edilmesinden sonra, gider artırıcı veya gelir
              azaltıcı bir karar alınması halinde, tahmini bütçe karara uygun olarak yenilenir ve genel kurulda yeniden
              oylanır.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Toplantıda görüşülen konular ve alınan kararlar bir tutanağa yazılır ve divan başkanı, başkan vekili ile
              yazman tarafından birlikte imzalanır. Toplantı sonunda, tutanak ve diğer belgeler Federasyon Yönetim
              Kurulu başkanına teslim edilir. Federasyon Yönetim Kurulu başkanı, bu belgelerin korunmasından ve yeni
              seçilen Federasyon Yönetim Kuruluna yedi gün içinde teslim etmekten sorumludur.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon Genel kurul toplantısı Federasyon Yönetim Kurulu tarafından uygun görülmesi halinde Bakanlıkça
              belirlenecek usul ve esaslar çerçevesinde elektronik ortamda gerçekleştirilebilir.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Federasyon Genel Kurul Sonuç Bildirimi</h6>
        <p>
          <b>Madde 21-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon Genel Kurul yapıldıktan sonra on beş gün içinde aşağıda sayılan bilgi ve belgeler Bakanlığa
              verilir:
              <ol>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon organlarına seçilen asıl ve yedek üyeleri içeren Yönetmeliğin eki EK-2’de yer alan
                  FEDERASYON GENEL KURUL SONUÇ BİLDİRİMİ.
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>Federasyon Genel kurul kararlarının birer örneği.</li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon Genel kurul toplantısında tüzük değişikliği yapılması halinde; tüzüğün değişen maddelerinin
                  eski ve yeni şekli, her sayfası Yönetim Kurulu üyelerinin salt çoğunluğunca imzalanmış Federasyon
                  tüzüğünün son şekli.
                </li>
              </ol>
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Birinci fıkranın (a) bendinde sayılan genel kurul sonuç bildirimi aynı süre içerisinde mülki idare
              amirliğine de verilir.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Genel kurul sonuç bildirimleri, Federasyon Yönetim Kurulu tarafından yetki verilen bir Yönetim Kurulu
              üyesi tarafından da yapılabilir.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Bu maddede belirtilen şekilde gerekli bildirimlerin yapılmamasından Yönetim Kurulu başkanı sorumludur.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Federasyon Yönetim Kurulu</h6>
        <p>
          <b>Madde 22-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon Yönetim Kurulu, genel kurulca iki yıl için gizli oyla seçilen yapımcı meslek birlikleri toplamı
              (Müyap + Müyabir + Müzikbir) dört üye, icracı meslek birlikleri toplamı (Müyorbir + Tsmb) üç üye olacak
              şekilde toplam yedi üyeden oluşur. Ana esas bu olmak üzere 48’inci maddede belirtilen ortak veri tabanının
              oluşturulmasından ve 35’inci maddede belirtilen dağıtımın da kullanım oranları esas alınarak yapılmasını
              takip eden ilk genel kurul toplantısından itibaren başlamak üzere Federasyonun Yönetim Kurulunda görev
              alacak Yönetim Kurulu üyeleri aşağıdaki esaslara göre belirlenecektir:
              <br />
              - Federasyonun Yönetim Kurulunda her bir meslek birliği en az bir adet üyeliğe her zaman sahip olacaktır.
              <br />
              - Buna göre yapımcı meslek birliklerinin ve icracı meslek birliklerinin en az üyelik sayısı dışında
              kalacak birer üyelikler, 48’inci madde gereğince ortak veri tabanının kurulmasının ardından kullanım
              oranlarına göre yapımcı (Müyap, Müzikbir ve Müyabir arasında) ve icracı (Müyorbir ve Tsmb arasında) MÜZFED
              - MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU Tüzüğü 8/15 meslek birliklerinden hangisinin temsil
              ettiği repertuvar diğerine veya diğerlerine göre daha fazla kullanıldığı belirlenmiş ise o meslek
              birliğine ait olacaktır.
              <br />
              - Yönetim Kurulu üyeliklerinin sayıları ile ilgili çalışmayı Yönetim Kurulu, kullanım oranlarına göre
              dağıtımın yapılmasından itibaren başlamak üzere 24 ay sonraki ilk genel kurul toplantısından iki ay
              öncesine kadar yaparak meslek birliklerine bildirecektir. Sonraki genel kurul toplantılarında da aynı usul
              devam ettirilir.
              <br />
              - Meslek birliklerinin bildirecekleri Yönetim Kurulu üyeliği adayları arasından seçimler de bu esaslara
              göre yapılacaktır.
              <br />
              Ayrıca aynı usul takip edilerek asıl üye sayısınca yedek üye seçilir. Federasyon Yönetim Kurulu, seçimi
              izleyen yedi gün içinde asıl üyeler arasından bir başkan, en az bir başkan yardımcısı ve sayman üye seçer.
              Yönetim Kurulu başkanı fonogram yapımcılarından seçilmişse, başkan yardımcısı icracı sanatçılardan, başkan
              icracı sanatçılardan seçilmişse, başkan yardımcısı fonogram yapımcılarından, sayman üye de üçüncü bir
              meslek birliğini temsil eden üyeler arasından seçilir.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              48’inci madde gereğince ortak veri tabanının kurulmasının ardından kullanım oranlarına göre belirlenmesine
              kadar Yönetim Kurulu, genel kurulca iki yıl için gizli oyla Müyap delegeleri arasından seçilen iki üye,
              Müyorbir delegeleri arasından seçilen iki üye, Tsmb delegeleri arasından seçilen bir üye, Müyabir
              delegeleri arasından seçilen bir üye, Müzikbir delegeleri arasından seçilen bir üye olacak şekilde yedi
              üyeden oluşur.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon Yönetim Kurulu; başkanın, yokluğunda yardımcısının çağrısıyla toplanır.
            </li>

            <li style={{ listStyleType: "decimal" }}>
              Aşağıdaki kararlar Tüzüğün 32’nci maddesinin istisnası olarak Federasyon Yönetim Kurulu tarafından
              oybirliği ile alınır.
              <ol>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon tüzüğünde yapılacak değişiklik önerilerinin hazırlanması
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon yönergelerinde yapılacak değişiklik önerilerinin belirlenmesi
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Genel Kurulun onayına sunulacak hususların belirlenmesi
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon genel kurul gündeminin belirlenmesi ve gündeme konu bilgi ve belgelerin genel kurul
                  toplantısından en az on beş gün önce üyelerin bilgisine sunulması
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon üyelik başvurularına karar verilmesi ve üyelik başvurularının reddine yönelik itirazları
                  yeniden değerlendirilmesi
                </li>
              </ol>
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Federasyon Yönetim Kurulu’nun Görev ve Yetkileri</h6>
        <p>
          <b>Madde 23-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon Yönetim Kurulu’nun görevleri ve yetkileri şunlardır:
              <ol>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Üye meslek birliklerinin üyesi fonogram yapımcılarının ve icracı sanatçıların haklarının idaresi ve
                  takibi için gerekli çalışmaları yapmak ve haklarına tecavüz durumunda her türlü adli, idari ve icrai
                  yollara başvurmak,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Kaynaklarının etkili, ekonomik ve verimli bir şekilde elde edilmesini ve kullanılmasını sağlamak,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Genel sekreteri ile Federasyonda çalışacak personeli görevlendirmek, görevlerine son vermek, bu
                  kişilerin özlük, unvan, ücret, sosyal hakları ve çalışma esasları ile diğer hususlara ilişkin usul ve
                  esasları belirlemek.
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon bütçesini hazırlamak ve genel kurula sunmak.
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon tüzüğünde yapılacak değişiklik önerilerini hazırlamak ve federasyon genel kuruluna sunmak.
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon tarafından tahsil olunacak tazminat ve telif ücretlerinden federasyonun faaliyetlerinin
                  yürütülebilmesi için kesilecek federasyon payında değişiklikleri genel kurula sunmak.
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>Tarifeleri hazırlamak.</li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon üyelik başvurularına karar vermek ve üyelik başvurularının reddine yönelik itirazları
                  yeniden değerlendirmek,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon tüzüğü ile öngörülen ölçütler doğrultusunda federasyon üyeliğine ilişkin işlemleri
                  gerçekleştirmek.
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyonun çalışma ve işleyişine ilişkin yönergeleri hazırlamak ve genel kurula sunmak.
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon Denetleme Kurulu raporlarının tesliminden itibaren birer örneğini on beş gün içinde
                  Bakanlığa göndermek.
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon yıllık şeffaflık raporunda yer alan mali bilgiler ile Federasyon hesaplarını yeminli mali
                  müşavire onaylatmak.
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon genel kurul gündemini belirlemek ve gündeme konu bilgi ve belgeleri genel kurul
                  toplantısından en az on beş gün önce üyelerin bilgisine sunmak.
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>Temsilcilik açılmasına karar vermek.</li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Kuruluş amaçlarıyla ilgili konularda ilgili Bakanlıklar ile iş birliği ve iletişim sürecini yürütmek.
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyonun amacına ve faaliyet alanına ilişkin şirket kurmasına ve kurulmuş olan şirketlere ortak
                  olmasına dair karar almak ve onaya sunmak.
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Mevzuat ve Federasyon Tüzüğüyle verilen diğer işleri yapmak.
                </li>
              </ol>
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Federasyonun Temsili</h6>
        <p>
          <b>Madde 24-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Yönetim kurulu Federasyonun yürütme ve temsil organı olup, bu görevini mevzuat ve bu Tüzüğe uygun olarak
              yerine getirir.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Hakların izlenmesinde, Federasyonun kamu kurum ve kuruluşlarıyla ve üçüncü kişilerle ilişkilerinde genel
              sekretere ve şubelerin en yüksek görevlisine, yönetim kurulu kararıyla imza ve temsil yetkisi verilebilir.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Federasyon Denetleme Kurulu</h6>
        <p>
          <b>Madde 25-</b>
          <br />
          Federasyon Denetleme Kurulu, genel kurulca iki yıl için gizli oyla Genel Kurul delegeleri arasından seçilen
          beş üyeden oluşur. Ayrıca aynı usul takip edilerek asıl üye sayısınca yedek üye seçilir. Federasyon Denetleme
          Kurulu, seçimi izleyen yedi gün içinde asıl üyeler içinden bir başkan seçer.
        </p>

        <h6 className="fw-bold">Federasyon Denetleme Kurulunun Görevleri</h6>
        <p>
          <b>Madde 26-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Federasyonda iç denetim esastır. Federasyon Genel kurulu, Federasyon Yönetim Kurulu veya Federasyon
              Denetleme Kurulu tarafından iç denetim yapılabileceği gibi, bağımsız denetim kuruluşlarına da denetim
              yaptırılabilir. Federasyon Genel kurul, Federasyon Yönetim Kurulu veya bağımsız denetim kuruluşlarınca
              denetim yapılmış olması, Denetleme Kurulunun yükümlülüğünü ortadan kaldırmaz.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon Denetleme Kurulu;
              <ol>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon Yönetim Kurulunun işlemlerinin mevzuata ve Federasyon Tüzüğüne uygun olup olmadığını,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon defter, hesap ve kayıtlarının mevzuata ve Federasyon tüzüğüne uygun olarak tutulup
                  tutulmadığını,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon telif gelirlerinin arttırılmasına yönelik çalışmalar yapılıp yapılmadığını,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon harcamalarının mevzuata ve Federasyonun amacına uygun olup olmadığını, Bir yılı geçmeyen
                  aralıklarla ve Federasyon Tüzüğünde tespit edilen esas ve usullere göre denetler. Bu hususlara ilişkin
                  bilgi, belge ve değerlendirmelerin yer aldığı denetim sonuçlarını 31’inci maddede düzenlenen Mali
                  Denetim Kurulu isimli ihtiyari organın raporlarını da değerlendirmek suretiyle bir rapor halinde
                  Yönetim Kuruluna ve toplandığında genel kurula sunar.
                </li>
              </ol>
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon Denetleme Kurulu üyelerinin talebi üzerine, her türlü bilgi, belge ve kayıtların, Federasyon
              yetkilileri tarafından gösterilmesi veya verilmesi, yönetim yerleri, müesseseler ve eklentilerine girme
              isteğinin yerine getirilmesi zorunludur.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Federasyon Teknik- Bilim Kurulu</h6>
        <p>
          <b>Madde 27-</b>
          <br />
          Federasyon Teknik-Bilim Kurulu, genel kurulca iki yıl için gizli oyla Genel Kurul delegeleri arasından seçilen
          üç üyeden oluşur. Ayrıca aynı usul takip edilerek asıl üye sayısınca yedek üye seçilir. Federasyon
          Teknik-Bilim Kurulu, seçimi izleyen yedi gün içinde asıl üyeleri arasından bir başkan seçer.
        </p>

        <h6 className="fw-bold">Federasyon Teknik-Bilim Kurulunun Görevleri</h6>
        <p>
          <b>Madde 28-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon Teknik-Bilim Kurulu;
              <ol>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon Yönetim Kurulunca incelenmesi istenilen konularda raporlar düzenlemek ve bunların bir
                  örneğini de denetleme kuruluna vermekle,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyon Tüzüğünde belirtilen diğer işleri yapmakla görevlidir.
                </li>
              </ol>
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Kurul başkanınca veya kurulca gerekli görülürse konuyla ilgili kurum ve kuruluşların temsilcileri veya
              kişiler bilgi ve görüşleri alınmak üzere kurul toplantılarına çağırılabilir.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Haysiyet Kurulu</h6>
        <p>
          <b>Madde 29-</b>
          <br />
          Haysiyet Kurulu, genel kurulca iki yıl için gizli oyla Genel Kurul delegeleri arasından seçilen üç üyeden
          oluşur. Ayrıca aynı usul takip edilerek asıl üye sayısınca yedek üye seçilir. Kurul seçimi izleyen yedi gün
          içinde asıl üyeler arasından bir başkan seçer.
        </p>

        <h6 className="fw-bold">Haysiyet Kurulunun Görevleri</h6>
        <p>
          <b>Madde 30-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Haysiyet Kurulu Federasyon Disiplin yönergesini uygulamakla görevlidir. Disiplin yönergesi Federasyon
              Haysiyet Kurulunca hazırlanır, Federasyon Yönetim Kurulunca incelenir ve Federasyon Genel Kurulunca kabul
              edilir.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon Disiplin yönergesinde;
              <ol>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Aynı fiil için birden fazla disiplin cezası verilememesi,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  İncelenen konuya ilişkin lehe ve aleyhe bilgi ve belgeler toplanmadan üye hakkında karar alınmaması,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>Savunma alınmadan ceza tayin edilmemesi,</li>
              </ol>
              Yönünde düzenlemeler yapılır.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon Haysiyet Kurulunca; Yönetim Kurulunun talebi üzerine veya re’sen disiplin incelemesi
              yapılabilir.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon Haysiyet kurulu kararlarına karşı yargı yolu açıktır.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Mali Denetim Kurulu ve Görevleri</h6>
        <p>
          <b>Madde 31-</b>
          <br />
          Mali Denetim Kurulu, Federasyon üyesi meslek birliklerinin yönetim kurullarınca alınacak kararlar ile bu
          kurulda görev almak ve ilgili meslek birliğini kurulda temsil etmek üzere seçilip Federasyona yazılı olarak
          bildirilecek birer asıl ve birer yedek olmak üzere toplam beş asıl ve beş yedek mali müşavir sıfatını haiz
          üyeden teşekkül eden ihtiyari bir organdır. Mali Denetim Kurulu üyelerinin görevleri, bir sonraki genel kurul
          toplantısına kadar devam eder. Kararları bağlayıcı olmayıp sadece bir inceleme raporu sunulması şeklindedir.
          Sekreteryasını Federasyon tarafından görevlendirilen sorumlu yürütür. Ayda en az bir defa olmak üzere
          toplantılarını gerçekleştirir. Görüşlerini ve yazılı beyanlarını toplantıya katılan üyelerinin imzası ile
          Federasyona bildirir. Raporları Denetleme Kuruluna ayrıca bildirilir. Mali Denetim Kurulu, Federasyonun tüm
          muhasebe kayıtlarını inceleyebilir. Mali Denetim Kurulunun görevleri aşağıda belirtilmiştir: - Federasyonun
          muhasebe kayıtlarının birer aylık periyotlar halinde incelenerek kullanıcılara kesilecek faturaların, yapılan
          tahsilatların incelenmesi, buna karşılık meslek birlikleri tarafından dağıtım raporlarına göre federasyona
          kesilecek fatura tutarlarının ve federasyon tarafından meslek birliklerine gönderilecek tutarların incelenmesi
          ve rapor olarak sunulması. - Federasyonun yönetimi ve Federasyon üyelerinin isteyeceği diğer muhasebe
          incelemelerinin yapılması. - Muhasebe kayıtlarında bir usulsüzlük tespit edilmesi halinde buna ilişkin raporun
          Denetleme Kurulu’na gönderilmesi.
        </p>

        <h6 className="fw-bold">Zorunlu Organların Toplantı ve Karar Yeter Sayısı</h6>
        <p>
          <b>Madde 32-</b>
          <br />
          Federasyon Yönetim Kurulu, Federasyon Denetleme Kurulu, Federasyon Haysiyet Kurulu ve Federasyon Teknik-Bilim
          Kurulları en az üye tam sayısının salt çoğunluğuyla toplanır ve toplantıya katılan üyelerin salt çoğunluğuyla
          karar alır. Yönetim Kurulu’nun oy birliği ile alacağı kararlar 22’nci maddede belirtilmiştir. Mazeretsiz
          olarak üst üste üç toplantıya katılmayan asıl üyelerin ilgili kurul üyeliği düşer ve kurulu üyeliği düşenin
          yeri hangi meslek birliğinin üyesi olarak seçilmiş ise, varsa ilgili meslek birliğinin bildirdiği yedek üye
          zorunlu organda ilk genel kurula kadar görev yapmaya devam eder.
        </p>

        <h6 className="fw-bold">Şubeler:</h6>
        <p>
          <b>Madde 33-</b>
          <br />
          Federasyon, faaliyetlerini yürütmek için gerekli gördüğü yerlerde kendisine bağlı şubeler açabilir. Ancak
          şubelerin tüzel kişiliği ve organları olamaz. Şubeler, Federasyon genel kurullarında temsil edilmezler.
          Şubenin adresi, yönetim kurulu kararıyla şube yetkilisi olarak görevlendirilen kişi veya kişiler tarafından
          Bakanlığa yazılı olarak bildirilir.
        </p>

        <h6 className="mt-100 fw-bold text-center">DÖRDÜNCÜ BÖLÜM</h6>
        <h6 className="fw-bold text-center">Mali Hükümler</h6>

        <h6 className="fw-bold">Federasyonun Gelirleri ve Kesinti Payı</h6>
        <p>
          <b>Madde 34-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Federasyonun gelirleri şunlardır:
              <ol>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyonun telif/izin gelirlerinin ve tazminatların tahsilinden elde edilen gelirler ile bu
                  gelirlerin yatırımından doğan gelirler,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Federasyona giriş aidatı ve üyelik aidatı gelirleri ile telif gelirlerinin ve tazminatların
                  tahsilinden elde edilen gelirlerden kesilecek birlik payları,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Sahip olduğu herhangi bir malvarlığı ile bu varlıklarından doğan gelirler,
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>Diğer gelirler.</li>
              </ol>
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Birinci fıkranın (a) bendinde belirtilen gelirler, diğer gelirlerden ayrı bir hesapta tutulur.
            </li>
            <li style={{ listStyleType: "decimal" }}>Birlik gelirleri alındı belgesi veya fatura ile tahsil edilir.</li>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon, yukarıdaki 1’inci fıkrada belirtilen gelirlerden Federasyon kesinti payı olarak, her bir üye
              meslek birliğinin elde edeceği toplam gelirinin %15’ine kadar kesinti yapabilir. Bu husustaki düzenlemeler
              ilgili Yönerge’de düzenlenir.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Dağıtım ve dağıtım yönergesi</h6>
        <p>
          <b>Madde 35-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon üyesi olan meslek birliklerinin Federasyon gelirlerinden toplam pay oranları kendi aralarında
              şu şekildedir:
              <br />
              Fonogram Yapımcıları Sahipleri meslek birlikleri (Mü-Yap + Müyabir + Müzikbir) toplamı: %65,00 İcracı
              meslek birlikleri (Müyorbir + Tsmb) toplamı: %35,00 Federasyona yeni bir meslek birliğinin üye olması
              halinde, fonogram yapımcılığı ve icracı sanatçı alanları için belirlenen dağıtım pay oranları değişmeyecek
              ve ilgili alandaki gelir paylaşımı, fonogram yapımcıları ve icracı sanatçılar alanındaki meslek
              birliklerinin kendi aralarında yapacakları anlaşmalara göre ve toplamda kendi alan payını aşmayacak
              şekilde belirlenecektir
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon tarafından konaklama tesislerinden tahsil edilen telif/izin gelirleri ve tazminat bedelleri;
              tahsili giderleri ile 34’üncü maddenin 4’üncü fıkrasında belirtilen federasyon payı düşüldükten sonra bir
              aylık süreyi geçmemek kaydı ile mümkün olan en kısa sürede;
              <br />- 48’inci maddedeki ortak veri tabanı oluşturulana kadar Müzikbir ve Müyabir’in her biri Müyap’ın
              %5’i, TSMB de Müyorbir’in %5’i üzerinden yapılacak hesaplamalara göre,
              <br />- 48’inci maddede belirtilen ortak veri tabanının kurulmasından sonra ise yukarıda Müyabir, Müzikbir
              ve Tsmb meslek birlikleri için belirtilen oranların altında olmamak koşuluyla ilgili yönergede belirlenen
              usuller ile elde edilecek kullanım oranları esas alınarak
              <br />
              Dağıtılır
              <br />
              Kullanım oranları üzerinden yapılacak hesaplamalar hiçbir şart altında, Fonogram yapımcıları ile icracı
              meslek birlikleri arasındaki pay oranlarına (%65,00 ve %35,00) halel getirmez.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon Dağıtımlarına ilişkin ödeme listesi yıllık olarak Bakanlığa bildirilir.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Dağıtım, yukarıdaki ana kurallar çerçevesinde kullanım oranlarının elde edilmesi ile ilgili tüm hususlar
              genel kurul tarafından kabul edilen ilgili Yönergeye göre yapılır.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Federasyon Kurul Üyelerine ve Görevlilere Yapılacak Ödemeler</h6>
        <p>
          <b>Madde 36-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Zorunlu organ üyelerine huzur hakkı verilebilir. Zorunlu organların asıl üyesi olmak koşuluyla, bir ay
              içinde yapılan toplantı sayısı kaç olursa olsun zorunlu organ üyelerine bir ayda en fazla dört toplantı
              üzerinden hesaplanacak şekilde, toplantı başına her bir asıl üye için huzur hakkı ödenir.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon hizmetleri için görevlendirilecek üyelere verilecek gündelik ve yolluk miktarları ile huzur
              hakları, genel kurul tarafından tespit olunur.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Genel Kurul haricinde zorunlu organların toplantılarına şehir dışına katılım halinde Yönetim Kurulu
              tarafından belirlenecek yolluk ve harcırahlar da ödenecektir.
            </li>
          </ol>
        </p>

        <h6 className="mt-100 fw-bold text-center">BEŞİNCİ BÖLÜM</h6>
        <h6 className="fw-bold text-center">Birlik Tüzel Kişiliğinin Sona Ermesi</h6>

        <h6 className="fw-bold">Federasyon Genel Kurul Kararıyla Sona Erme</h6>
        <p>
          <b>Madde 37-</b>
          <br />
          Federasyon Genel Kurulunda hazır bulunan oyların üçte ikisi ile Federasyonun feshine karar verilebilir. Fesih
          kararı, federasyon genel kurul divan başkanı tarafından on beş gün içinde Bakanlığa bildirilir.
        </p>

        <h6 className="fw-bold">Kendiliğinden Sona Erme:</h6>
        <p>
          <b>Madde 38-</b>
          <br />
          Federasyon,
          <ol>
            <li style={{ listStyleType: "lower-alpha" }}>
              Bu Tüzükte federasyon genel kurulunun toplanması için belirlenen yeter sayısının sağlanamaması nedeniyle
              olağan federasyon genel kurul toplantısının yapılamaması,
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>
              Federasyon zorunlu organların oluşturulmasının olanaksız hale gelmesi,
            </li>
            <li style={{ listStyleType: "lower-alpha" }}>Federasyonun borç ödemede acze düşmesi.</li>
          </ol>
          İle sona erer.
        </p>

        <h6 className="fw-bold">Tasfiye</h6>
        <p>
          <b>Madde 39-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon Genel Kurul kararı ile feshedilen veya kendiliğinden sona erdiği tespit edilen Federasyonun
              para, mal ve hakların tasfiyesi bu Tüzükte gösterilen esaslara göre yapılır.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon para, mal ve haklarının devri, son Federasyon Yönetim Kurulu üyelerinden oluşan tasfiye
              kurulunca yapılır. Bu işlemlere, feshe ilişkin genel kurul kararlarının alındığı veya kendiliğinden sona
              erme halinin kesinleştiği tarihten itibaren başlanır. Tasfiye süresi içerisinde bütün işlemlerde “Tasfiye
              Halinde MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU” ibaresi kullanılır.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Tasfiye Kurulu, önce MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’nun hesaplarını inceler.
              İnceleme esnasında MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’na ait defterler, alındı
              belgeleri, tapu ve banka kayıtları ile diğer belgelerin tespiti yapılarak varlık ve yükümlülükler bir
              tutanağa bağlanır. Bu tutanak derhal Bakanlığa gönderilir. Tasfiye işlemi sırasında varsa MÜZFED- MÜZİK
              SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’nun tüm nakit parası, taşınır ve taşınmaz mallarının satılması ve
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’nun alacaklı olması durumunda borçlulardan
              alacakları tahsil edilmesi sonucunda toplanan tüm para, MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR
              FEDERASYONU banka hesabına yatırılır. Tasfiye işlemleri sırasında MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI
              HAKLAR FEDERASYONU, ilk olarak varsa vergi ve SGK prim borçlarını, istihdam ettiği çalışanlara
              ücretlerini, ikramiyelerini, kullanılmayan yıllık izin ücretlerini, kıdem ve ihbar tazminatları ile İş
              Kanundan doğan yükümlülükleri kapsamında gerekli diğer ödemeleri yapar. Bu ödemelerin yapılmasına müteakip
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU alacaklılarına çağrıda bulunulur ve MÜZFED- MÜZİK
              SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU borçları MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU
              banka hesabında kalan paradan ödenir. Borçların ödenmesi ve alacakların tahsil edilmesinden sonra geriye
              para kalması halinde bu para, üye meslek birliklerine bu Tüzükte belirlenen pay oranları esas alınarak
              dağıtılır.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Tasfiyeye ilişkin tüm işlemler tasfiye tutanağında gösterilir ve tasfiye işlemleri, Bakanlıkça haklı bir
              nedene dayanılarak verilen ek süreler hariç üç ay içinde tamamlanır.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU yukarıda belirtilen hükümler kapsamında para, mal
              ve hakların tasfiye ve intikal işlemlerinin tamamlanmasını müteakip Tasfiye Kurulu tarafından durum yedi
              gün içinde bir yazı ile Bakanlığa bildirilir ve bu yazıya tasfiye tutanağı eklenir.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Bu hükümlerin defter ve belgelerini tasfiye kurulu sıfatıyla son Yönetim Kurulu üyeleri saklamakla
              görevlidir. Bu görev, bir Yönetim Kurulu üyesine de verilebilir. Bu defter ve belgelerin saklanma süresi
              beş yıldır.
            </li>
          </ol>
        </p>

        <h6 className="mt-100 fw-bold text-center">ALTINCI BÖLÜM</h6>
        <h6 className="fw-bold text-center">Defter ve Kayıtlar</h6>

        <h6 className="fw-bold">Defter tutma esasları</h6>
        <p>
          <b>Madde 40-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU, bilanço esasına göre defter tutar.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU üst üste iki hesap döneminde Telif Hakları Alanında
              Meslek Birlikleri Yönetmeliğinin 46’ncı maddesinin birinci fıkrasında belirtilen parasal sınırın altına
              düşerse, takip eden yıldan itibaren işletme hesabı esasına dönebilir.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU bir ticari işletmeye sahip olması durumunda ticari
              işletmesi için ayrıca 4/1/1961 tarihli ve 213 sayılı Vergi Usul Kanunu hükümlerine göre defter tutar.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Tutulacak defterler</h6>
        <p>
          <b>Madde 41-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU bilanço esasında tutacağı defterler ve uyacağı
              esaslar şunlardır:
              <ol>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Karar defteri: Yönetim Kurulu kararları tarih ve numara sırasıyla bu deftere yazılır ve kararların
                  altına toplantıya katılan üyelerce imzalanır.
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Evrak kayıt defteri: Gelen ve giden evraklar, tarih ve sıra numarası ile bu deftere kaydedilir. Gelen
                  evrakın asılları ve giden evrakın kopyaları dosyalanır. Elektronik posta yoluyla gelen veya giden
                  evraklar çıktısı alınmak suretiyle saklanır.
                </li>
                <li style={{ listStyleType: "lower-alpha" }}>Demirbaş defteri.</li>
                <li style={{ listStyleType: "lower-alpha" }}>
                  Yevmiye defteri ve büyük defter: Bu defterlerin tutulma usulünde ve kayıt şeklinde, 213 sayılı Kanun
                  ile bu Kanunun Hazine ve Maliye Bakanlığına verdiği yetkiye istinaden yayımlanan Muhasebe Sistemi
                  Uygulama Genel Tebliğleri esaslarına uyulur.
                </li>
              </ol>
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Yevmiye defteri ve büyük defter Yönetmelik hükümlerine aykırı olmamak şartı ile elektronik ortamda
              tutulmalarına ilişkin Hazine ve Maliye Bakanlığı ile Ticaret Bakanlığınca çıkartılan tebliğlerde
              belirtilen usul ve esaslar çerçevesinde, diğer defterler ise Bakanlıkça oluşturulan veya izin verilen
              yazılımlar kullanılarak elektronik ortamda da tutulabilir.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Kayıt usulü</h6>
        <p>
          <b>Madde 42-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Bu Tüzüğe göre tutulacak defter ve kayıtların Türkçe olması zorunludur. Defterler mürekkepli kalemle
              yazılır.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Defterler elektronik ortamda da tutulabilir. Ancak form veya sürekli form şeklinde tutulacak defterler,
              kullanılmaya başlanmadan önce her bir sayfasına numara verilerek ve notere onaylatılarak kullanılabilir.
              Onaylı sayfalar kullanıldıktan sonra defter haline getirilerek muhafaza edilir.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Yevmiye defteri maddelerinde yapılan yanlışlar ancak muhasebe kurallarına göre düzeltilebilir. Diğer
              defter ve kayıtlara rakam ve yazılar yanlış yazıldığı takdirde düzeltmeler ancak yanlış rakam ve yazı
              okunacak şekilde çizilmek, üst veya yan tarafına veya ilgili bulunduğu hesaba doğrusu yazılmak suretiyle
              yapılabilir. Yanlış rakam ve yazımın çizilmesi halinde, bu rakam ve yazıyı çizen tarafından paraflanır.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Defterlere geçirilen bir kayıt; kazınmak, çizmek veya silmek suretiyle okunamaz hale getirilemez.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Karar defterinin sayfa sonunda imza için bırakılan bölümü hariç defterlerin satırları, çizilmeksizin boş
              bırakılamaz ve atlanamaz. Ciltli defterlerde, defter sayfaları ciltten koparılamaz. Tasdikli form veya
              sürekli form yapraklarının sırası bozulamaz ve bunlar yırtılamaz.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’na ait belgeler, kaydedildikleri defterdeki kayıt
              sırasına uygun olarak numaralandırılır ve dosyalanarak saklanır.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Kayıt zamanı</h6>
        <p>
          <b>Madde 43-</b>
          <br />
          İşlemler, defterlere günlük olarak kaydedilir. Ancak gelir ve gider kayıtları; işlemlerin, işin hacmine ve
          gereklerine uygun olarak muhasebe düzeni ve güvenliğini bozmayacak bir süre içinde kaydedilmesi şarttır. Bu
          gibi kayıtlar on günden fazla geciktirilmez.
        </p>

        <h6 className="fw-bold">Hesap dönemi</h6>
        <p>
          <b>Madde 43-</b>
          <br />
          MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’nda hesap dönemi bir takvim yılıdır. Hesap dönemi 1
          Ocak’ta başlar ve 31 Aralık’ta sona erer.
        </p>

        <h6 className="fw-bold">Defterlerin tasdiki</h6>
        <p>
          <b>Madde 45-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Elektronik ortamda tutacağı defterler hariç olmak üzere MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR
              FEDERASYONU bu Tüzük uyarınca tutacağı yazılı defterleri kullanmaya başlamadan önce notere tasdik ettirir.
              Bu defterlerin kullanılmasına sayfalar bitene kadar devam edilir ve defterlerin ara tasdiki yapılmaz.
              Ancak yevmiye defteri kullanılacağı yıldan önce gelen son ayda, her yıl yeniden tasdik ettirilir. Büyük
              defterin onaylatılması zorunlu değildir.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Tasdik edilen her defter için ayrı bir tasdik numarası verilir. MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR
              FEDERASYONU’nun adı, kütük numarası, yerleşim yeri, defterin türü, defterin kaç sayfadan ibaret olduğu,
              tasdik tarihi, tasdik numarası, tasdiki yapan makamın resmi mühür ve imzasını içeren tasdik şerhi formu
              doldurulup defterin ilk sayfasına yapıştırılarak köşeleri tasdiki yapan makam tarafından mühürlenir.
              Defterin son sayfası, defterin kaç sayfadan ibaret olduğu, tasdik tarihi ve numarası belirtilerek tasdik
              makamı tarafından mühürlenir ve imzalanır.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Defterlerin her sayfası sıra numarasıyla teselsül edip etmediği kontrol edilerek mühürlenir.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Gelir ve gider belgeleri</h6>
        <p>
          <b>Madde 46-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU gelirleri, alındı belgesi veya fatura ile tahsil
              edilir. MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU gelirlerinin tahsilinde kullanılacak alındı
              belgeleri, Federasyon Yönetim Kurulu kararıyla bastırılır ve sayman üye tarafından kontrol edilir.
              Bastırılan alındı belgesi bilgilerinin on beş gün içinde Bakanlığa bildirilmesi zorunludur. Sayman üye,
              boş ve kullanılmış alındı belgelerinin saklanmasından sorumludur. MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI
              HAKLAR FEDERASYONU gelirlerinin bankalar aracılığı ile tahsili halinde banka tarafından düzenlenen dekont
              veya hesap özeti gibi belgeler alındı belgesi yerine geçer. MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR
              FEDERASYONU kasasında bulundurulabilecek para miktarı, ihtiyaçlar dikkate alınarak Federasyon Yönetim
              Kurulunca belirlenir.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU giderleri, gerçek ve tüzel kişilerden alınacak 213
              sayılı Kanun gereğince düzenlenen fatura ve fatura yerine gelen belgeler ile tevsik edilir.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Yetki belgesi</h6>
        <p>
          <b>Madde 47-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU üye meslek birliklerince verilecek yetki belgesine
              konu hakları kendi adına kullanmaya, üçüncü kişilere kullandırmaya, kullanmaktan menetmeye, kullanımlar
              karşılığında istenen ücreti tahsil etmeye, bu hakların takibi ve tahsili için her türlü kazai, idari ve
              icrai yollara başvurmaya ve takip etmeye münhasıran yetkili ve yükümlüdür
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Ekte yer alan ve Tüzük’ün 4’üncü maddesine uygun olarak düzenlenen yetki belgesine konu her bir hakkın
              türü ve kapsamının MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’nun koyacağı kriterlere uygun
              olarak belirlenmesi MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU tarafından üyelik için
              hazırlanan yetki belgesinin değiştirilmemesi, yetki belgesine ekleme ve çıkarmalar yapılmaması esastır. Bu
              esaslara aykırı şekilde verilen yetki belgelerini MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU
              kabul etmek zorunda değildir.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Ortak Veri Tabanı</h6>
        <p>
          <b>Madde 48-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              Federasyon, Telif Hakları Alanında Meslek Birlikleri Yönetmeliğinin ilgili 9’uncu maddesinin ç bendi
              kapsamında yönettiği haklarla ilgili bağlantılı hak konularına ilişkin olarak güncellemek, üyesi olan
              meslek birliklerine arasındaki paylaşımda esas almak ve ilgililerin bilgisine sunmak üzere bir ortak veri
              tabanı oluşturacaktır.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Ortak veri tabanında Federasyon üyesi meslek birliklerinin hak takip yetkisine sahip oldukları fonogramlar
              ve icraların bilgileri yer alacak olup Meslek Birlikleri kendi korumaları altındaki fonogram ve icralara
              ilişkin bilgileri, veri tabanı oluşturmak üzere Federasyona bildirirler.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">İlgili Mevzuat</h6>
        <p>
          <b>Madde 49-</b>
          <ol>
            <li style={{ listStyleType: "decimal" }}>
              İşbu ‘MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU Tüzüğü’nde hüküm bulunmayan hallerde “TELİF
              HAKLARI ALANINDA MESLEK BİRLİKLERİ YÖNETMELİĞİ” uygulanır.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Yönetmelikte federasyonların çalışmalarıyla ilgili hususlarda hüküm bulunmayan hallerde 4/11/2004 tarihli
              ve 5253 sayılı Dernekler Kanunu, 22/11/2001 tarihli ve 4721 sayılı Türk Medeni Kanunu ve Türk Medeni
              Kanun’un derneklere ilişkin hükümleri ile 31/3/2005 tarihli ve 25772 sayılı Resmî Gazete’de yayımlanan
              Dernekler Yönetmeliğinin ilgili hükümleri uygulanır.
            </li>
          </ol>
        </p>

        <h6 className="fw-bold">Yürürlük</h6>
        <p>
          <b>Madde 50-</b>
          <br />
          Bu Tüzük, MÜZFED- MÜZİK SEKTÖRÜNDE BAĞLANTILI HAKLAR FEDERASYONU’nun kurulması ile yürürlüğe girer.
          <br />
          <u>Geçici madde 1:</u> 5’inci maddede belirtilen ve Federasyonun kurulmasından önce üye meslek birlikleri ile
          imzalanmış olan konaklama tesisleri sözleşmeleri ile ilgili bilgiler (unvan, logo, adres ve yetkili isimleri)
          ilgili üye meslek birliği tarafından Federasyonun kurulmasından itibaren geçecek bir aylık süre içerisinde
          Federasyona bildirilir. Federasyon Yönetim Kurulu, 2023 yılına münhasır olarak Federasyon üyesi olan meslek
          birliklerinin Federasyonun kurulması tarihinde mevcut konaklama tesisi sözleşmelerinden hangilerinin meslek
          birliği bünyesinde devam edeceğine ve Federasyonun da sadece bu sözleşmelerin bedellerinin tahsili iş ve
          işlemlerini yürüteceğine karar verebilir.
          <br />
          <u>Geçici madde 2:</u> Gerek 48’inci maddede belirtilen ortak veri tabanının oluşturulması ve gerekse de
          35’inci maddede belirtilen dağılım esaslarında yorumcu ve eşlikçi icracılar arasında uygulanması gereken
          oranlamanın uluslararası uygulamalar gözetilerek tespiti ile ilgili olarak akademisyen ve sektör
          temsilcilerinin de yer alacağı bir ekip tarafından araştırma yapılarak rapor halinde sunulması için Bakanlık
          destekli proje, Federasyonun kurulmasından itibaren geçecek bir yıllık süre içerisinde tamamlanmak üzere
          Bakanlığa sunulacaktır. Bu hususta yapılacak mali destek başvurusu Tsmb tarafından yapılacak olup diğer meslek
          birlikleri projede paydaş olarak yer alacaklardır.
        </p>
      </Col>
    </Row>
  </Container>
);

export default Charter;
