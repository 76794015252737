import { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Tooltip } from "reactstrap";

const SubPageFooter = () => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <footer>
      <div className="tp-footer-area black-bg pt-130 pb-30">
        <Container>
          <Row className="wow tpfadeUp" data-wow-duration=".3s" data-wow-delay=".5s">
            <Col md={5}>
              <div className="tp-footer-widget__logo mb-30">
                <Link to="/">
                  <img src={require("@muzfed/assets/img/logo/muzfed-logo-light.png")} alt="Logo" />
                </Link>
              </div>
              <div className="tp-footer-widget__text mb-30">
                <p>
                  MÜZFED, 5846 sayılı Fikir ve Sanat Eserleri kanununa göre kurulmuş olan Müyap, Müyorbir, Müyabir,
                  Müzikbir ve TSMB meslek birliklerinin kurduğu müzik sektöründe fonogram yapımcıları ve icracıların
                  haklarını koruyan federasyondur.
                </p>
              </div>
            </Col>
            <Col md={7} className="d-flex justify-content-lg-center">
              <div className="tp-footer-widget">
                <div className="tp-footer-widget__title pb-15">
                  <h3 className="footer-title">Linkler</h3>
                </div>
                <div className="tp-footer-widget__list">
                  <ul>
                    <li>
                      <Link to="/hakkimizda/biz-kimiz">Hakkımızda</Link>
                    </li>
                    <li>
                      <Link to="/iletisim">İletşim</Link>
                    </li>
                    <li>
                      <Link to="/firmalar">Lisans Alan Firmalar</Link>
                    </li>
                    <li>
                      <Link to="/tarifeler">Tarifeler</Link>
                    </li>
                    <li>
                      <Link to="/sikca-sorulan-sorular">Sıkça Sorulan Sorular</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="tp-copyright-area">
          <Container>
            <div className="copyright-border pt-40 wow tpfadeUp" data-wow-duration=".5s" data-wow-delay=".7s">
              <Row>
                <Col xl={3} lg={3}>
                  <div className="tp-copyright-left text-lg-start text-start text-md-center">
                    <p>Her Hakkı Saklıdır</p>
                  </div>
                </Col>
                <Col xl={9} lg={9}>
                  <div className="tp-copyright-right text-start text-md-center text-lg-end">
                    <Link to="/gizlilik?t=1">Kişisel Verilen Korunması ve İşlenmesi Kanunu</Link>
                    <Link to="/gizlilik?t=4">Çerez Politikası</Link>
                    <Link to="/gizlilik?t=2">Aydınlatma ve Gizlilik Politikası</Link>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="d-flex justify-content-end align-items-center">
                  <Link to="http://kuryaz.com" target="_blank" rel="noreferrer" id="kuryazLink">
                    <img
                      src={require("@muzfed/assets/img/logo/kurgu_logo.png")}
                      alt="Kurgu Yazılım"
                      width={50}
                      height={50}
                    />
                  </Link>
                  <Tooltip isOpen={tooltipOpen} target="kuryazLink" toggle={toggle}>
                    Kurgu Yazılım
                  </Tooltip>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </footer>
  );
};

export default SubPageFooter;
